:root {
  --bg-color-light: #ffffff;
  --bg-color-dark: #1a1a1a;
  --primary: #782dc8;
  --cubic: cubic-bezier(0.4, 0, 0.2, 1);
  --opacity: 0.6; /* Define the opacity variable here */
}

.light-theme {
  --bg-color: var(--bg-color-light) !important;
  --raised-bg: white;
  --text-color: black;
}

.dark-theme {
  --bg-color: var(--bg-color-dark);
  --raised-bg: #151619;
  --text-color: white;
  font-weight: 500;
}

.blur {
  background: linear-gradient(
    90deg,
    #44ff9a -0.55%,
    #44b0ff 22.86%,
    #8b44ff 48.36%,
    #f64 73.33%,
    #ebff70 99.34%
  );
  filter: blur(16px);
  top: 0;
  opacity: 0.4;
  z-index: 100;
  position: fixed;
  transform: rotate(180deg);
}

.Toastify__toast {
  z-index: 1000000000;
}

.swagger-ui {
  width: 100%;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0) rotate(-1deg);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0) rotate(1deg);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0) rotate(-0.5deg);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0) rotate(0.5deg);
  }
}

.shake-on-load {
  animation: shake 0.72s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.nothing-found-container {
  width: 100%;
  font-size: 30px;
  margin-top: 30px;
  font-weight: 400;
  text-align: center;
  animation: fadeIn 0.3s var(--cubic) forwards;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-btn {
  content: "";
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
  color: white;
  font-weight: 700;
  padding-left: 20px;
  width: 100%;
  padding-right: 20px;
  background: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  animation: gradient 3s ease infinite alternate;
  background-size: 300% 300%;
  max-width: 450px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-weight: 500;
  scroll-behavior: smooth;
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}

#ck-app p {
  border-radius: 32px !important;
}

#ck-app button {
  border-radius: 32px !important;
  font-weight: 600 !important;
}

.loading {
  opacity: 0.5;
}

#ck-app div {
  z-index: 1000 !important;
}

#ck-cf-modal {
  border-radius: 20px !important;
  z-index: 12000 !important;
}

#ck-theme-pre-section {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  width: max-content !important;
  min-width: 300px !important;
}

html {
  overflow-y: scroll;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.gradient-text {
  background: linear-gradient(to right, pink, purple);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}

.gradient-text2 {
  background: linear-gradient(-65deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.flex-gap-3 {
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 3px;
}
.flex-gap-10 {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.flex-gap-5 {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
}
.error-message {
  background-color: #f31260;
  padding: 10px;
  border-radius: 100px;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: 800;
  padding-top: 15px;
  padding-bottom: 15px;
}

.loader-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.loader-wrapper-models {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  opacity: 0;
  transition: all 0.14s ease;
  transform: scale(0);
}
.loader-wrapper-models-visible {
  opacity: 1;
  transform: scale(1);
}
.container-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 76px);
  width: 100%;
  flex-direction: column;
}

.ps-sidebar-container {
  /* border-radius: 16px; */
  /* background-color: transparent; */
}

.ps-menu-button {
  font-size: 17px;
  font-weight: 600;
}

.main {
  justify-content: center;
  margin-top: 30px;

  display: flex;
  height: 100%;
  max-width: 1440px;
  padding-inline: 1.5rem;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  /* padding-left: 250px !important; */
}

.terms-and-conditions {
  display: block;
}

.terms-and-conditions a {
  font-weight: bold;
  text-decoration: underline;
  color: var(--primary);
}

.terms-and-conditions ol,
.terms-and-conditions ul {
  padding-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

ul {
  margin: 0px;
}

li {
  margin-bottom: 0px;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.vertical-flex-10 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.vertical-flex-15 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.container {
  display: flex;
  justify-self: center;
  margin-top: 40px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-top: 2em;
  min-height: calc(100vh - 76px);
}

.title {
  font-size: 38px;
  font-weight: bold;
  letter-spacing: -1px;
}

.subtitle {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pulsating-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;
}

.error-text {
  width: 100%;
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  color: red;
  margin-bottom: 5px;
}

.pulsating-circle:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #90ee90;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #90ee90;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

.pulsating-circle2 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;
}

.pulsating-circle2:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #782dc8;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle2:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #782dc8;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.pulsating-circle3 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;
}

.pulsating-circle3:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #dfff00;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle3:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #dfff00;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

.line {
  height: 2px !important;
}
.errorSvg {
  height: 200px;
}

.raised-container {
  width: 100%;
  padding: 30px;
  /* border: 1px solid rgb(184, 184, 184); */
  border-radius: 16px;
  box-shadow: 0px 16px 24px 0px rgba(8, 60, 148, 0.16);
  gap: 20px;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 1200px) {
  .main {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    padding-inline: 1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .rc-footer-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px; /* Adjust this value as needed for spacing between items */
  }
}

@media only screen and (max-width: 610px) {
  .main {
    max-width: 100vw;
    padding-left: 25px;
    padding-right: 25px;
    min-width: 100vw;
    overflow: hidden;
  }

  .container-center {
    justify-content: flex-start;
    padding-top: 100px;
    padding-inline: 20px;
  }
}

.mobile-hidden {
  display: block;
}
.desktop-hidden {
  display: none;
}

@media only screen and (max-width: 410px) {
  .mobile-hidden {
    display: none;
  }
  .desktop-hidden {
    display: block;
  }

  #ck-theme-pre-section {
    display: none !important;
  }
}
