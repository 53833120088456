@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-width: 100%;
  overflow-y: auto !important;
  margin-right: 0 !important;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

h3 {
  letter-spacing: -0.025em;
  margin-top: 0px;
  margin-bottom: 0px;
}

h2 {
  letter-spacing: -0.025em;
}

h1 {
  letter-spacing: -0.025em;
}

p {
  letter-spacing: -0.025em;
}

button,
textarea,
input,
dialog {
  outline: none;
}

p {
  padding: none;
  margin: none;
}

.main-container {
  @apply tw-max-w-[1420px] tw-mt-8 tw-w-full tw-flex tw-h-full tw-flex-col tw-items-start tw-justify-start;
}

a,
div {
  -webkit-tap-highlight-color: transparent;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-fade-in {
  animation: fadeIn 0.4s ease-in-out forwards;
}

.rainbow-button {
  position: relative;
  text-decoration: none;
  border: none;
  cursor: pointer;
  background-size: 100%;
  /* padding: 20px 50px; */
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  border-radius: 50px;
  overflow: hidden;
  width: 130px;
  height: 37px;
}

.rainbow-button:before {
  content: "";
  display: block;
  position: absolute;
  left: -10px;
  top: 50%;
  width: 150px;
  height: 100px;
  border-radius: 50px;
  background-image: conic-gradient(
    from 180deg,
    #fd004c,
    #fe9000,
    #fff020,
    #3edf4b,
    #3363ff,
    #b102b7,
    #fd004c
  );
  filter: blur(12px) brightness(0.9);
  transform: translateY(-50%) rotate(0);
  transition: transform 0.3s ease-in-out;
  /* transition: all 0.3s ease-in-out; */
}

@keyframes spinAnimation {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}

.rainbow-button.spin:before {
  animation: spinAnimation 1s linear infinite;
}

/* thead th {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
} */

table {
  border-collapse: collapse;
}
