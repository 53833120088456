* {
    scrollbar-color: initial;
    scrollbar-width: initial;
}
*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}
.tw-container {
    width: 100%;
}
@media (min-width: 640px) {
    .tw-container {
        max-width: 640px;
    }
}
@media (min-width: 768px) {
    .tw-container {
        max-width: 768px;
    }
}
@media (min-width: 1024px) {
    .tw-container {
        max-width: 1024px;
    }
}
@media (min-width: 1280px) {
    .tw-container {
        max-width: 1280px;
    }
}
@media (min-width: 1536px) {
    .tw-container {
        max-width: 1536px;
    }
}
.tw-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
.tw-pointer-events-none {
    pointer-events: none;
}
.tw-visible {
    visibility: visible;
}
.tw-invisible {
    visibility: hidden;
}
.tw-fixed {
    position: fixed;
}
.tw-absolute {
    position: absolute;
}
.tw-relative {
    position: relative;
}
.tw-inset-0 {
    inset: 0px;
}
.tw-inset-\[2px\] {
    inset: 2px;
}
.tw-inset-x-0 {
    left: 0px;
    right: 0px;
}
.-tw-bottom-1 {
    bottom: -0.25rem;
}
.-tw-left-12 {
    left: -3rem;
}
.-tw-right-1 {
    right: -0.25rem;
}
.tw--bottom-1 {
    bottom: -0.25rem;
}
.tw--bottom-16 {
    bottom: -4rem;
}
.tw--bottom-2 {
    bottom: -0.5rem;
}
.tw--bottom-3 {
    bottom: -0.75rem;
}
.tw--bottom-4 {
    bottom: -1rem;
}
.tw--bottom-6 {
    bottom: -1.5rem;
}
.tw--bottom-7 {
    bottom: -1.75rem;
}
.tw--bottom-8 {
    bottom: -2rem;
}
.tw--bottom-9 {
    bottom: -2.25rem;
}
.tw--left-0 {
    left: -0px;
}
.tw--left-7 {
    left: -1.75rem;
}
.tw--right-0 {
    right: -0px;
}
.tw--right-0\.5 {
    right: -0.125rem;
}
.tw--right-1 {
    right: -0.25rem;
}
.tw--right-2 {
    right: -0.5rem;
}
.tw--top-0 {
    top: -0px;
}
.tw--top-0\.5 {
    top: -0.125rem;
}
.tw--top-1 {
    top: -0.25rem;
}
.tw--top-12 {
    top: -3rem;
}
.tw--top-2 {
    top: -0.5rem;
}
.tw--top-44 {
    top: -11rem;
}
.tw--top-5 {
    top: -1.25rem;
}
.tw--top-8 {
    top: -2rem;
}
.tw-bottom-0 {
    bottom: 0px;
}
.tw-bottom-1 {
    bottom: 0.25rem;
}
.tw-bottom-10 {
    bottom: 2.5rem;
}
.tw-bottom-2 {
    bottom: 0.5rem;
}
.tw-bottom-3 {
    bottom: 0.75rem;
}
.tw-bottom-4 {
    bottom: 1rem;
}
.tw-bottom-5 {
    bottom: 1.25rem;
}
.tw-bottom-7 {
    bottom: 1.75rem;
}
.tw-bottom-8 {
    bottom: 2rem;
}
.tw-bottom-9 {
    bottom: 2.25rem;
}
.tw-left-0 {
    left: 0px;
}
.tw-left-1\/2 {
    left: 50%;
}
.tw-left-2 {
    left: 0.5rem;
}
.tw-left-2\.5 {
    left: 0.625rem;
}
.tw-left-3 {
    left: 0.75rem;
}
.tw-left-4 {
    left: 1rem;
}
.tw-left-5 {
    left: 1.25rem;
}
.tw-left-6 {
    left: 1.5rem;
}
.tw-left-\[10\%\] {
    left: 10%;
}
.tw-left-\[2vw\] {
    left: 2vw;
}
.tw-right-0 {
    right: 0px;
}
.tw-right-1 {
    right: 0.25rem;
}
.tw-right-2 {
    right: 0.5rem;
}
.tw-right-2\.5 {
    right: 0.625rem;
}
.tw-right-3 {
    right: 0.75rem;
}
.tw-right-4 {
    right: 1rem;
}
.tw-right-5 {
    right: 1.25rem;
}
.tw-right-6 {
    right: 1.5rem;
}
.tw-right-8 {
    right: 2rem;
}
.tw-right-auto {
    right: auto;
}
.tw-top-0 {
    top: 0px;
}
.tw-top-1 {
    top: 0.25rem;
}
.tw-top-1\/2 {
    top: 50%;
}
.tw-top-10 {
    top: 2.5rem;
}
.tw-top-16 {
    top: 4rem;
}
.tw-top-2 {
    top: 0.5rem;
}
.tw-top-2\.5 {
    top: 0.625rem;
}
.tw-top-24 {
    top: 6rem;
}
.tw-top-3 {
    top: 0.75rem;
}
.tw-top-36 {
    top: 9rem;
}
.tw-top-4 {
    top: 1rem;
}
.tw-top-5 {
    top: 1.25rem;
}
.tw-top-6 {
    top: 1.5rem;
}
.tw-top-\[105px\] {
    top: 105px;
}
.tw-top-\[1px\] {
    top: 1px;
}
.tw-top-\[40px\] {
    top: 40px;
}
.tw-top-\[60\%\] {
    top: 60%;
}
.tw-top-\[65px\] {
    top: 65px;
}
.tw-top-\[initial\] {
    top: initial;
}
.tw-top-full {
    top: 100%;
}
.tw-z-0 {
    z-index: 0;
}
.tw-z-10 {
    z-index: 10;
}
.tw-z-20 {
    z-index: 20;
}
.tw-z-30 {
    z-index: 30;
}
.tw-z-50 {
    z-index: 50;
}
.tw-z-\[10000\] {
    z-index: 10000;
}
.tw-z-\[1000\] {
    z-index: 1000;
}
.tw-z-\[100\] {
    z-index: 100;
}
.tw-z-\[1\] {
    z-index: 1;
}
.tw-z-\[2\] {
    z-index: 2;
}
.tw-z-\[300\] {
    z-index: 300;
}
.tw-z-\[400\] {
    z-index: 400;
}
.tw-z-\[50000000\] {
    z-index: 50000000;
}
.tw-z-\[52\] {
    z-index: 52;
}
.tw-z-\[5\] {
    z-index: 5;
}
.tw-z-\[900\] {
    z-index: 900;
}
.tw-col-span-1 {
    grid-column: span 1 / span 1;
}
.tw-col-span-2 {
    grid-column: span 2 / span 2;
}
.tw-col-span-3 {
    grid-column: span 3 / span 3;
}
.tw-col-span-full {
    grid-column: 1 / -1;
}
.tw-m-0 {
    margin: 0px;
}
.tw-m-20 {
    margin: 5rem;
}
.tw-m-auto {
    margin: auto;
}
.-tw-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
}
.tw--mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
}
.tw-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.tw-mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.tw-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
}
.tw-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
.tw-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}
.tw-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.tw-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}
.tw-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.tw-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.tw-my-auto {
    margin-top: auto;
    margin-bottom: auto;
}
.-tw-mt-14 {
    margin-top: -3.5rem;
}
.tw--mb-1 {
    margin-bottom: -0.25rem;
}
.tw--ml-12 {
    margin-left: -3rem;
}
.tw--mr-12 {
    margin-right: -3rem;
}
.tw--mt-1 {
    margin-top: -0.25rem;
}
.tw--mt-10 {
    margin-top: -2.5rem;
}
.tw--mt-14 {
    margin-top: -3.5rem;
}
.tw--mt-24 {
    margin-top: -6rem;
}
.tw--mt-28 {
    margin-top: -7rem;
}
.tw--mt-36 {
    margin-top: -9rem;
}
.tw--mt-8 {
    margin-top: -2rem;
}
.tw-mb-0 {
    margin-bottom: 0px;
}
.tw-mb-0\.5 {
    margin-bottom: 0.125rem;
}
.tw-mb-1 {
    margin-bottom: 0.25rem;
}
.tw-mb-1\.5 {
    margin-bottom: 0.375rem;
}
.tw-mb-10 {
    margin-bottom: 2.5rem;
}
.tw-mb-16 {
    margin-bottom: 4rem;
}
.tw-mb-2 {
    margin-bottom: 0.5rem;
}
.tw-mb-24 {
    margin-bottom: 6rem;
}
.tw-mb-28 {
    margin-bottom: 7rem;
}
.tw-mb-3 {
    margin-bottom: 0.75rem;
}
.tw-mb-4 {
    margin-bottom: 1rem;
}
.tw-mb-5 {
    margin-bottom: 1.25rem;
}
.tw-mb-6 {
    margin-bottom: 1.5rem;
}
.tw-mb-8 {
    margin-bottom: 2rem;
}
.tw-mb-auto {
    margin-bottom: auto;
}
.tw-ml-0 {
    margin-left: 0px;
}
.tw-ml-0\.5 {
    margin-left: 0.125rem;
}
.tw-ml-1 {
    margin-left: 0.25rem;
}
.tw-ml-1\.5 {
    margin-left: 0.375rem;
}
.tw-ml-2 {
    margin-left: 0.5rem;
}
.tw-ml-3 {
    margin-left: 0.75rem;
}
.tw-ml-4 {
    margin-left: 1rem;
}
.tw-ml-5 {
    margin-left: 1.25rem;
}
.tw-ml-6 {
    margin-left: 1.5rem;
}
.tw-ml-auto {
    margin-left: auto;
}
.tw-mr-0 {
    margin-right: 0px;
}
.tw-mr-0\.5 {
    margin-right: 0.125rem;
}
.tw-mr-1 {
    margin-right: 0.25rem;
}
.tw-mr-1\.5 {
    margin-right: 0.375rem;
}
.tw-mr-2 {
    margin-right: 0.5rem;
}
.tw-mr-2\.5 {
    margin-right: 0.625rem;
}
.tw-mr-3 {
    margin-right: 0.75rem;
}
.tw-mr-4 {
    margin-right: 1rem;
}
.tw-mt-0 {
    margin-top: 0px;
}
.tw-mt-0\.5 {
    margin-top: 0.125rem;
}
.tw-mt-1 {
    margin-top: 0.25rem;
}
.tw-mt-1\.5 {
    margin-top: 0.375rem;
}
.tw-mt-10 {
    margin-top: 2.5rem;
}
.tw-mt-14 {
    margin-top: 3.5rem;
}
.tw-mt-16 {
    margin-top: 4rem;
}
.tw-mt-2 {
    margin-top: 0.5rem;
}
.tw-mt-24 {
    margin-top: 6rem;
}
.tw-mt-3 {
    margin-top: 0.75rem;
}
.tw-mt-32 {
    margin-top: 8rem;
}
.tw-mt-4 {
    margin-top: 1rem;
}
.tw-mt-5 {
    margin-top: 1.25rem;
}
.tw-mt-6 {
    margin-top: 1.5rem;
}
.tw-mt-8 {
    margin-top: 2rem;
}
.tw-mt-auto {
    margin-top: auto;
}
.tw-line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.tw-line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.tw-block {
    display: block;
}
.tw-inline-block {
    display: inline-block;
}
.tw-inline {
    display: inline;
}
.tw-flex {
    display: flex;
}
.tw-inline-flex {
    display: inline-flex;
}
.tw-grid {
    display: grid;
}
.tw-hidden {
    display: none;
}
.tw-aspect-\[9\/16\] {
    aspect-ratio: 9/16;
}
.tw-aspect-square {
    aspect-ratio: 1 / 1;
}
.tw-size-1 {
    width: 0.25rem;
    height: 0.25rem;
}
.tw-size-1\.5 {
    width: 0.375rem;
    height: 0.375rem;
}
.tw-size-10 {
    width: 2.5rem;
    height: 2.5rem;
}
.tw-size-12 {
    width: 3rem;
    height: 3rem;
}
.tw-size-14 {
    width: 3.5rem;
    height: 3.5rem;
}
.tw-size-16 {
    width: 4rem;
    height: 4rem;
}
.tw-size-2 {
    width: 0.5rem;
    height: 0.5rem;
}
.tw-size-24 {
    width: 6rem;
    height: 6rem;
}
.tw-size-3 {
    width: 0.75rem;
    height: 0.75rem;
}
.tw-size-3\.5 {
    width: 0.875rem;
    height: 0.875rem;
}
.tw-size-32 {
    width: 8rem;
    height: 8rem;
}
.tw-size-36 {
    width: 9rem;
    height: 9rem;
}
.tw-size-4 {
    width: 1rem;
    height: 1rem;
}
.tw-size-40 {
    width: 10rem;
    height: 10rem;
}
.tw-size-48 {
    width: 12rem;
    height: 12rem;
}
.tw-size-5 {
    width: 1.25rem;
    height: 1.25rem;
}
.tw-size-52 {
    width: 13rem;
    height: 13rem;
}
.tw-size-6 {
    width: 1.5rem;
    height: 1.5rem;
}
.tw-size-8 {
    width: 2rem;
    height: 2rem;
}
.tw-size-\[18px\] {
    width: 18px;
    height: 18px;
}
.tw-h-0 {
    height: 0px;
}
.tw-h-0\.5 {
    height: 0.125rem;
}
.tw-h-1 {
    height: 0.25rem;
}
.tw-h-1\.5 {
    height: 0.375rem;
}
.tw-h-10 {
    height: 2.5rem;
}
.tw-h-11 {
    height: 2.75rem;
}
.tw-h-12 {
    height: 3rem;
}
.tw-h-14 {
    height: 3.5rem;
}
.tw-h-16 {
    height: 4rem;
}
.tw-h-2 {
    height: 0.5rem;
}
.tw-h-2\.5 {
    height: 0.625rem;
}
.tw-h-2\/3 {
    height: 66.666667%;
}
.tw-h-20 {
    height: 5rem;
}
.tw-h-24 {
    height: 6rem;
}
.tw-h-3 {
    height: 0.75rem;
}
.tw-h-3\.5 {
    height: 0.875rem;
}
.tw-h-32 {
    height: 8rem;
}
.tw-h-36 {
    height: 9rem;
}
.tw-h-4 {
    height: 1rem;
}
.tw-h-40 {
    height: 10rem;
}
.tw-h-44 {
    height: 11rem;
}
.tw-h-48 {
    height: 12rem;
}
.tw-h-5 {
    height: 1.25rem;
}
.tw-h-52 {
    height: 13rem;
}
.tw-h-56 {
    height: 14rem;
}
.tw-h-6 {
    height: 1.5rem;
}
.tw-h-60 {
    height: 15rem;
}
.tw-h-64 {
    height: 16rem;
}
.tw-h-7 {
    height: 1.75rem;
}
.tw-h-72 {
    height: 18rem;
}
.tw-h-8 {
    height: 2rem;
}
.tw-h-\[120px\] {
    height: 120px;
}
.tw-h-\[125px\] {
    height: 125px;
}
.tw-h-\[130px\] {
    height: 130px;
}
.tw-h-\[150px\] {
    height: 150px;
}
.tw-h-\[200px\] {
    height: 200px;
}
.tw-h-\[266px\] {
    height: 266px;
}
.tw-h-\[300px\] {
    height: 300px;
}
.tw-h-\[4\.5rem\] {
    height: 4.5rem;
}
.tw-h-\[400px\] {
    height: 400px;
}
.tw-h-\[44px\] {
    height: 44px;
}
.tw-h-\[500px\] {
    height: 500px;
}
.tw-h-\[50px\] {
    height: 50px;
}
.tw-h-\[65px\] {
    height: 65px;
}
.tw-h-\[68px\] {
    height: 68px;
}
.tw-h-\[70px\] {
    height: 70px;
}
.tw-h-\[84px\] {
    height: 84px;
}
.tw-h-\[calc\(100vh\)\] {
    height: calc(100vh);
}
.tw-h-\[calc\(100vh-50px\)\] {
    height: calc(100vh - 50px);
}
.tw-h-\[var\(--radix-navigation-menu-viewport-height\)\] {
    height: var(--radix-navigation-menu-viewport-height);
}
.tw-h-\[var\(--radix-select-trigger-height\)\] {
    height: var(--radix-select-trigger-height);
}
.tw-h-auto {
    height: auto;
}
.tw-h-full {
    height: 100%;
}
.tw-h-max {
    height: -moz-max-content;
    height: max-content;
}
.tw-h-px {
    height: 1px;
}
.tw-h-screen {
    height: 100vh;
}
.tw-max-h-60 {
    max-height: 15rem;
}
.tw-max-h-72 {
    max-height: 18rem;
}
.tw-max-h-80 {
    max-height: 20rem;
}
.tw-max-h-96 {
    max-height: 24rem;
}
.tw-max-h-\[150px\] {
    max-height: 150px;
}
.tw-max-h-\[340px\] {
    max-height: 340px;
}
.tw-max-h-\[375px\] {
    max-height: 375px;
}
.tw-max-h-\[500px\] {
    max-height: 500px;
}
.tw-max-h-\[50vh\] {
    max-height: 50vh;
}
.tw-max-h-\[70vh\] {
    max-height: 70vh;
}
.tw-max-h-\[96\%\] {
    max-height: 96%;
}
.tw-min-h-10 {
    min-height: 2.5rem;
}
.tw-min-h-12 {
    min-height: 3rem;
}
.tw-min-h-14 {
    min-height: 3.5rem;
}
.tw-min-h-44 {
    min-height: 11rem;
}
.tw-min-h-48 {
    min-height: 12rem;
}
.tw-min-h-72 {
    min-height: 18rem;
}
.tw-min-h-\[150px\] {
    min-height: 150px;
}
.tw-min-h-screen {
    min-height: 100vh;
}
.tw-w-0 {
    width: 0px;
}
.tw-w-0\.5 {
    width: 0.125rem;
}
.tw-w-1 {
    width: 0.25rem;
}
.tw-w-1\.5 {
    width: 0.375rem;
}
.tw-w-1\/2 {
    width: 50%;
}
.tw-w-1\/3 {
    width: 33.333333%;
}
.tw-w-10 {
    width: 2.5rem;
}
.tw-w-10\/12 {
    width: 83.333333%;
}
.tw-w-11 {
    width: 2.75rem;
}
.tw-w-11\/12 {
    width: 91.666667%;
}
.tw-w-12 {
    width: 3rem;
}
.tw-w-14 {
    width: 3.5rem;
}
.tw-w-16 {
    width: 4rem;
}
.tw-w-2 {
    width: 0.5rem;
}
.tw-w-2\.5 {
    width: 0.625rem;
}
.tw-w-2\/5 {
    width: 40%;
}
.tw-w-20 {
    width: 5rem;
}
.tw-w-24 {
    width: 6rem;
}
.tw-w-28 {
    width: 7rem;
}
.tw-w-3 {
    width: 0.75rem;
}
.tw-w-3\.5 {
    width: 0.875rem;
}
.tw-w-3\/4 {
    width: 75%;
}
.tw-w-3\/5 {
    width: 60%;
}
.tw-w-32 {
    width: 8rem;
}
.tw-w-4 {
    width: 1rem;
}
.tw-w-40 {
    width: 10rem;
}
.tw-w-48 {
    width: 12rem;
}
.tw-w-5 {
    width: 1.25rem;
}
.tw-w-56 {
    width: 14rem;
}
.tw-w-6 {
    width: 1.5rem;
}
.tw-w-60 {
    width: 15rem;
}
.tw-w-64 {
    width: 16rem;
}
.tw-w-7 {
    width: 1.75rem;
}
.tw-w-72 {
    width: 18rem;
}
.tw-w-8 {
    width: 2rem;
}
.tw-w-80 {
    width: 20rem;
}
.tw-w-9\/12 {
    width: 75%;
}
.tw-w-\[120px\] {
    width: 120px;
}
.tw-w-\[130px\] {
    width: 130px;
}
.tw-w-\[200px\] {
    width: 200px;
}
.tw-w-\[250px\] {
    width: 250px;
}
.tw-w-\[2px\] {
    width: 2px;
}
.tw-w-\[300px\] {
    width: 300px;
}
.tw-w-\[50px\] {
    width: 50px;
}
.tw-w-\[60px\] {
    width: 60px;
}
.tw-w-\[750px\] {
    width: 750px;
}
.tw-w-\[80\%\] {
    width: 80%;
}
.tw-w-\[94vw\] {
    width: 94vw;
}
.tw-w-auto {
    width: auto;
}
.tw-w-full {
    width: 100%;
}
.tw-w-max {
    width: -moz-max-content;
    width: max-content;
}
.tw-w-screen {
    width: 100vw;
}
.tw-min-w-10 {
    min-width: 2.5rem;
}
.tw-min-w-12 {
    min-width: 3rem;
}
.tw-min-w-14 {
    min-width: 3.5rem;
}
.tw-min-w-40 {
    min-width: 10rem;
}
.tw-min-w-60 {
    min-width: 15rem;
}
.tw-min-w-64 {
    min-width: 16rem;
}
.tw-min-w-8 {
    min-width: 2rem;
}
.tw-min-w-80 {
    min-width: 20rem;
}
.tw-min-w-96 {
    min-width: 24rem;
}
.tw-min-w-\[160px\] {
    min-width: 160px;
}
.tw-min-w-\[200px\] {
    min-width: 200px;
}
.tw-min-w-\[300px\] {
    min-width: 300px;
}
.tw-min-w-\[8rem\] {
    min-width: 8rem;
}
.tw-min-w-\[var\(--radix-select-trigger-width\)\] {
    min-width: var(--radix-select-trigger-width);
}
.tw-min-w-full {
    min-width: 100%;
}
.tw-max-w-2xl {
    max-width: 42rem;
}
.tw-max-w-3xl {
    max-width: 48rem;
}
.tw-max-w-40 {
    max-width: 10rem;
}
.tw-max-w-4xl {
    max-width: 56rem;
}
.tw-max-w-80 {
    max-width: 20rem;
}
.tw-max-w-96 {
    max-width: 24rem;
}
.tw-max-w-\[1400px\] {
    max-width: 1400px;
}
.tw-max-w-\[1420px\] {
    max-width: 1420px;
}
.tw-max-w-\[150px\] {
    max-width: 150px;
}
.tw-max-w-\[180px\] {
    max-width: 180px;
}
.tw-max-w-\[220px\] {
    max-width: 220px;
}
.tw-max-w-\[225px\] {
    max-width: 225px;
}
.tw-max-w-\[250px\] {
    max-width: 250px;
}
.tw-max-w-\[70\%\] {
    max-width: 70%;
}
.tw-max-w-\[800px\] {
    max-width: 800px;
}
.tw-max-w-full {
    max-width: 100%;
}
.tw-max-w-lg {
    max-width: 32rem;
}
.tw-max-w-max {
    max-width: -moz-max-content;
    max-width: max-content;
}
.tw-max-w-md {
    max-width: 28rem;
}
.tw-max-w-none {
    max-width: none;
}
.tw-max-w-sm {
    max-width: 24rem;
}
.tw-max-w-xl {
    max-width: 36rem;
}
.tw-max-w-xs {
    max-width: 20rem;
}
.tw-flex-1 {
    flex: 1 1 0%;
}
.tw-flex-shrink-0 {
    flex-shrink: 0;
}
.tw-shrink-0 {
    flex-shrink: 0;
}
.tw-flex-grow {
    flex-grow: 1;
}
.tw-grow {
    flex-grow: 1;
}
.tw-basis-72 {
    flex-basis: 18rem;
}
.tw-table-auto {
    table-layout: auto;
}
.tw-caption-bottom {
    caption-side: bottom;
}
.tw-origin-bottom {
    transform-origin: bottom;
}
.-tw-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-tw-translate-x-full {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-tw-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-tw-translate-y-4 {
    --tw-translate-y: -1rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-tw-translate-y-6 {
    --tw-translate-y: -1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw--translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw--translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-translate-x-full {
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-translate-y-10 {
    --tw-translate-y: 2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-translate-y-\[-20px\] {
    --tw-translate-y: -20px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-translate-y-\[0\] {
    --tw-translate-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw--rotate-90 {
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw--rotate-\[22deg\] {
    --tw-rotate: -22deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-rotate-45 {
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-rotate-6 {
    --tw-rotate: 6deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-rotate-\[-10deg\] {
    --tw-rotate: -10deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-rotate-\[10deg\] {
    --tw-rotate: 10deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-rotate-\[22deg\] {
    --tw-rotate: 22deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-scale-90 {
    --tw-scale-x: .9;
    --tw-scale-y: .9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-scale-\[0\.65\] {
    --tw-scale-x: 0.65;
    --tw-scale-y: 0.65;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-scale-\[1\.01\] {
    --tw-scale-x: 1.01;
    --tw-scale-y: 1.01;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-tw-scale-x-100 {
    --tw-scale-x: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes tw-blur-in-out {
    0%, 100% {
        filter: blur(0);
    }
    50% {
        filter: blur(12px);
    }
}
.tw-animate-blur-in-out {
    animation: tw-blur-in-out 2s infinite;
}
@keyframes tw-pulse {
    50% {
        opacity: .5;
    }
}
.tw-animate-pulse {
    animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes tw-slide-up {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}
.tw-animate-slide-up {
    animation: tw-slide-up .5s cubic-bezier(.32,.72,0,1);
}
@keyframes tw-spin {
    to {
        transform: rotate(360deg);
    }
}
.tw-animate-spin {
    animation: tw-spin 1s linear infinite;
}
@keyframes tw-spinner-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.tw-animate-spinner-ease-spin {
    animation: tw-spinner-spin 0.8s ease infinite;
}
.tw-cursor-default {
    cursor: default;
}
.tw-cursor-not-allowed {
    cursor: not-allowed;
}
.tw-cursor-pointer {
    cursor: pointer;
}
.tw-touch-none {
    touch-action: none;
}
.tw-select-none {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}
.tw-select-text {
    -webkit-user-select: text;
       -moz-user-select: text;
            user-select: text;
}
.tw-resize-none {
    resize: none;
}
.tw-list-inside {
    list-style-position: inside;
}
.tw-list-decimal {
    list-style-type: decimal;
}
.tw-list-disc {
    list-style-type: disc;
}
.tw-list-none {
    list-style-type: none;
}
.tw-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
.tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.tw-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
.tw-flex-row {
    flex-direction: row;
}
.tw-flex-row-reverse {
    flex-direction: row-reverse;
}
.tw-flex-col {
    flex-direction: column;
}
.tw-flex-col-reverse {
    flex-direction: column-reverse;
}
.tw-flex-wrap {
    flex-wrap: wrap;
}
.tw-items-start {
    align-items: flex-start;
}
.tw-items-end {
    align-items: flex-end;
}
.tw-items-center {
    align-items: center;
}
.tw-items-baseline {
    align-items: baseline;
}
.tw-justify-start {
    justify-content: flex-start;
}
.tw-justify-end {
    justify-content: flex-end;
}
.tw-justify-center {
    justify-content: center;
}
.tw-justify-between {
    justify-content: space-between;
}
.tw-justify-around {
    justify-content: space-around;
}
.tw-justify-evenly {
    justify-content: space-evenly;
}
.tw-gap-0 {
    gap: 0px;
}
.tw-gap-0\.5 {
    gap: 0.125rem;
}
.tw-gap-1 {
    gap: 0.25rem;
}
.tw-gap-1\.5 {
    gap: 0.375rem;
}
.tw-gap-10 {
    gap: 2.5rem;
}
.tw-gap-2 {
    gap: 0.5rem;
}
.tw-gap-2\.5 {
    gap: 0.625rem;
}
.tw-gap-3 {
    gap: 0.75rem;
}
.tw-gap-4 {
    gap: 1rem;
}
.tw-gap-5 {
    gap: 1.25rem;
}
.tw-gap-6 {
    gap: 1.5rem;
}
.tw-gap-7 {
    gap: 1.75rem;
}
.tw-gap-8 {
    gap: 2rem;
}
.tw-gap-x-2 {
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
}
.tw-gap-y-1 {
    row-gap: 0.25rem;
}
.tw-gap-y-4 {
    row-gap: 1rem;
}
.-tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.tw--space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.tw-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.tw-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.tw-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}
.tw-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.tw-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.tw-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.tw-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}
.tw-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.tw-divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.tw-divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-divide-opacity));
}
.tw-divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}
.tw-overflow-auto {
    overflow: auto;
}
.tw-overflow-hidden {
    overflow: hidden;
}
.tw-overflow-visible {
    overflow: visible;
}
.tw-overflow-x-auto {
    overflow-x: auto;
}
.tw-overflow-y-auto {
    overflow-y: auto;
}
.tw-overflow-y-hidden {
    overflow-y: hidden;
}
.tw-overflow-y-visible {
    overflow-y: visible;
}
.tw-overflow-x-scroll {
    overflow-x: scroll;
}
.tw-overflow-y-scroll {
    overflow-y: scroll;
}
.tw-scroll-smooth {
    scroll-behavior: smooth;
}
.tw-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tw-whitespace-nowrap {
    white-space: nowrap;
}
.tw-text-wrap {
    text-wrap: wrap;
}
.tw-text-nowrap {
    text-wrap: nowrap;
}
.tw-text-pretty {
    text-wrap: pretty;
}
.tw-break-all {
    word-break: break-all;
}
.tw-rounded {
    border-radius: 0.25rem;
}
.tw-rounded-2xl {
    border-radius: 1rem;
}
.tw-rounded-3xl {
    border-radius: 1.5rem;
}
.tw-rounded-4xl {
    border-radius: 32px;
}
.tw-rounded-5xl {
    border-radius: 44px;
}
.tw-rounded-\[19px\] {
    border-radius: 19px;
}
.tw-rounded-\[26px\] {
    border-radius: 26px;
}
.tw-rounded-\[28px\] {
    border-radius: 28px;
}
.tw-rounded-\[30px\] {
    border-radius: 30px;
}
.tw-rounded-\[4px\] {
    border-radius: 4px;
}
.tw-rounded-full {
    border-radius: 9999px;
}
.tw-rounded-lg {
    border-radius: 0.5rem;
}
.tw-rounded-md {
    border-radius: 0.375rem;
}
.tw-rounded-sm {
    border-radius: 0.125rem;
}
.tw-rounded-xl {
    border-radius: 0.75rem;
}
.tw-rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}
.tw-rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
}
.tw-rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
}
.tw-rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}
.tw-rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
}
.tw-rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}
.tw-rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.tw-rounded-t-\[10px\] {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.tw-rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}
.tw-rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
}
.tw-rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
}
.tw-rounded-br-2xl {
    border-bottom-right-radius: 1rem;
}
.tw-rounded-tl-2xl {
    border-top-left-radius: 1rem;
}
.tw-rounded-tl-sm {
    border-top-left-radius: 0.125rem;
}
.tw-rounded-tr-2xl {
    border-top-right-radius: 1rem;
}
.tw-border {
    border-width: 1px;
}
.tw-border-0 {
    border-width: 0px;
}
.tw-border-2 {
    border-width: 2px;
}
.tw-border-3 {
    border-width: 3px;
}
.tw-border-4 {
    border-width: 4px;
}
.tw-border-x-0 {
    border-left-width: 0px;
    border-right-width: 0px;
}
.tw-border-b {
    border-bottom-width: 1px;
}
.tw-border-b-2 {
    border-bottom-width: 2px;
}
.tw-border-t {
    border-top-width: 1px;
}
.tw-border-t-0 {
    border-top-width: 0px;
}
.tw-border-solid {
    border-style: solid;
}
.tw-border-dashed {
    border-style: dashed;
}
.tw-border-none {
    border-style: none;
}
.tw-border-amber-700 {
    --tw-border-opacity: 1;
    border-color: rgb(180 83 9 / var(--tw-border-opacity));
}
.tw-border-black {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity));
}
.tw-border-black\/10 {
    border-color: rgb(0 0 0 / 0.1);
}
.tw-border-black\/20 {
    border-color: rgb(0 0 0 / 0.2);
}
.tw-border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgb(243 244 246 / var(--tw-border-opacity));
}
.tw-border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.tw-border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.tw-border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgb(107 114 128 / var(--tw-border-opacity));
}
.tw-border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity));
}
.tw-border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgb(74 222 128 / var(--tw-border-opacity));
}
.tw-border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgb(34 197 94 / var(--tw-border-opacity));
}
.tw-border-neutral-100 {
    --tw-border-opacity: 1;
    border-color: rgb(245 245 245 / var(--tw-border-opacity));
}
.tw-border-neutral-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 229 229 / var(--tw-border-opacity));
}
.tw-border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgb(243 232 255 / var(--tw-border-opacity));
}
.tw-border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgb(233 213 255 / var(--tw-border-opacity));
}
.tw-border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgb(216 180 254 / var(--tw-border-opacity));
}
.tw-border-purple-300\/30 {
    border-color: rgb(216 180 254 / 0.3);
}
.tw-border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgb(192 132 252 / var(--tw-border-opacity));
}
.tw-border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgb(147 51 234 / var(--tw-border-opacity));
}
.tw-border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgb(126 34 206 / var(--tw-border-opacity));
}
.tw-border-purple-700\/50 {
    border-color: rgb(126 34 206 / 0.5);
}
.tw-border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgb(254 202 202 / var(--tw-border-opacity));
}
.tw-border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgb(239 68 68 / var(--tw-border-opacity));
}
.tw-border-slate-200 {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity));
}
.tw-border-slate-400 {
    --tw-border-opacity: 1;
    border-color: rgb(148 163 184 / var(--tw-border-opacity));
}
.tw-border-slate-950 {
    --tw-border-opacity: 1;
    border-color: rgb(2 6 23 / var(--tw-border-opacity));
}
.tw-border-transparent {
    border-color: transparent;
}
.tw-border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.tw-border-white\/10 {
    border-color: rgb(255 255 255 / 0.1);
}
.tw-border-white\/40 {
    border-color: rgb(255 255 255 / 0.4);
}
.tw-border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgb(250 204 21 / var(--tw-border-opacity));
}
.tw-border-zinc-300 {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 216 / var(--tw-border-opacity));
}
.tw-border-x-transparent {
    border-left-color: transparent;
    border-right-color: transparent;
}
.tw-border-t-transparent {
    border-top-color: transparent;
}
.tw-bg-amber-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(180 83 9 / var(--tw-bg-opacity));
}
.tw-bg-background {
    background-color: hsl(var(--background));
}
.tw-bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.tw-bg-black\/10 {
    background-color: rgb(0 0 0 / 0.1);
}
.tw-bg-black\/20 {
    background-color: rgb(0 0 0 / 0.2);
}
.tw-bg-black\/40 {
    background-color: rgb(0 0 0 / 0.4);
}
.tw-bg-black\/5 {
    background-color: rgb(0 0 0 / 0.05);
}
.tw-bg-black\/50 {
    background-color: rgb(0 0 0 / 0.5);
}
.tw-bg-black\/60 {
    background-color: rgb(0 0 0 / 0.6);
}
.tw-bg-black\/70 {
    background-color: rgb(0 0 0 / 0.7);
}
.tw-bg-black\/80 {
    background-color: rgb(0 0 0 / 0.8);
}
.tw-bg-black\/90 {
    background-color: rgb(0 0 0 / 0.9);
}
.tw-bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}
.tw-bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}
.tw-bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}
.tw-bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}
.tw-bg-border {
    background-color: hsl(var(--border));
}
.tw-bg-cyan-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}
.tw-bg-cyan-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}
.tw-bg-cyan-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(34 211 238 / var(--tw-bg-opacity));
}
.tw-bg-fuchsia-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(232 121 249 / var(--tw-bg-opacity));
}
.tw-bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.tw-bg-gray-100\/60 {
    background-color: rgb(243 244 246 / 0.6);
}
.tw-bg-gray-100\/80 {
    background-color: rgb(243 244 246 / 0.8);
}
.tw-bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.tw-bg-gray-200\/40 {
    background-color: rgb(229 231 235 / 0.4);
}
.tw-bg-gray-200\/60 {
    background-color: rgb(229 231 235 / 0.6);
}
.tw-bg-gray-200\/70 {
    background-color: rgb(229 231 235 / 0.7);
}
.tw-bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.tw-bg-gray-300\/20 {
    background-color: rgb(209 213 219 / 0.2);
}
.tw-bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}
.tw-bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.tw-bg-gray-50\/80 {
    background-color: rgb(249 250 251 / 0.8);
}
.tw-bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}
.tw-bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.tw-bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}
.tw-bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}
.tw-bg-green-200\/60 {
    background-color: rgb(187 247 208 / 0.6);
}
.tw-bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}
.tw-bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}
.tw-bg-green-400\/20 {
    background-color: rgb(74 222 128 / 0.2);
}
.tw-bg-green-400\/70 {
    background-color: rgb(74 222 128 / 0.7);
}
.tw-bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}
.tw-bg-green-50\/50 {
    background-color: rgb(240 253 244 / 0.5);
}
.tw-bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}
.tw-bg-lime-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}
.tw-bg-muted {
    background-color: hsl(var(--muted));
}
.tw-bg-muted\/50 {
    background-color: hsl(var(--muted) / 0.5);
}
.tw-bg-neutral-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}
.tw-bg-neutral-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}
.tw-bg-neutral-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}
.tw-bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}
.tw-bg-orange-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 215 170 / var(--tw-bg-opacity));
}
.tw-bg-orange-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 186 116 / var(--tw-bg-opacity));
}
.tw-bg-orange-300\/80 {
    background-color: rgb(253 186 116 / 0.8);
}
.tw-bg-orange-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}
.tw-bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}
.tw-bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}
.tw-bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 168 212 / var(--tw-bg-opacity));
}
.tw-bg-popover {
    background-color: hsl(var(--popover));
}
.tw-bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}
.tw-bg-purple-100\/50 {
    background-color: rgb(243 232 255 / 0.5);
}
.tw-bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}
.tw-bg-purple-200\/80 {
    background-color: rgb(233 213 255 / 0.8);
}
.tw-bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}
.tw-bg-purple-300\/10 {
    background-color: rgb(216 180 254 / 0.1);
}
.tw-bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}
.tw-bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}
.tw-bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}
.tw-bg-purple-500\/20 {
    background-color: rgb(168 85 247 / 0.2);
}
.tw-bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}
.tw-bg-purple-600\/90 {
    background-color: rgb(147 51 234 / 0.9);
}
.tw-bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}
.tw-bg-purple-700\/10 {
    background-color: rgb(126 34 206 / 0.1);
}
.tw-bg-purple-700\/20 {
    background-color: rgb(126 34 206 / 0.2);
}
.tw-bg-purple-700\/40 {
    background-color: rgb(126 34 206 / 0.4);
}
.tw-bg-purple-700\/80 {
    background-color: rgb(126 34 206 / 0.8);
}
.tw-bg-purple-700\/90 {
    background-color: rgb(126 34 206 / 0.9);
}
.tw-bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}
.tw-bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(88 28 135 / var(--tw-bg-opacity));
}
.tw-bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}
.tw-bg-red-200\/60 {
    background-color: rgb(254 202 202 / 0.6);
}
.tw-bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}
.tw-bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}
.tw-bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
.tw-bg-red-500\/20 {
    background-color: rgb(239 68 68 / 0.2);
}
.tw-bg-red-600\/20 {
    background-color: rgb(220 38 38 / 0.2);
}
.tw-bg-red-600\/70 {
    background-color: rgb(220 38 38 / 0.7);
}
.tw-bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(127 29 29 / var(--tw-bg-opacity));
}
.tw-bg-rose-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(251 113 133 / var(--tw-bg-opacity));
}
.tw-bg-sky-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(125 211 252 / var(--tw-bg-opacity));
}
.tw-bg-sky-400\/80 {
    background-color: rgb(56 189 248 / 0.8);
}
.tw-bg-slate-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}
.tw-bg-slate-950\/95 {
    background-color: rgb(2 6 23 / 0.95);
}
.tw-bg-teal-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(94 234 212 / var(--tw-bg-opacity));
}
.tw-bg-transparent {
    background-color: transparent;
}
.tw-bg-violet-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(221 214 254 / var(--tw-bg-opacity));
}
.tw-bg-violet-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(196 181 253 / var(--tw-bg-opacity));
}
.tw-bg-violet-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 243 255 / var(--tw-bg-opacity));
}
.tw-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.tw-bg-white\/10 {
    background-color: rgb(255 255 255 / 0.1);
}
.tw-bg-white\/40 {
    background-color: rgb(255 255 255 / 0.4);
}
.tw-bg-white\/5 {
    background-color: rgb(255 255 255 / 0.05);
}
.tw-bg-white\/60 {
    background-color: rgb(255 255 255 / 0.6);
}
.tw-bg-white\/90 {
    background-color: rgb(255 255 255 / 0.9);
}
.tw-bg-white\/95 {
    background-color: rgb(255 255 255 / 0.95);
}
.tw-bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}
.tw-bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 240 138 / var(--tw-bg-opacity));
}
.tw-bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 224 71 / var(--tw-bg-opacity));
}
.tw-bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 204 21 / var(--tw-bg-opacity));
}
.tw-bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}
.tw-bg-zinc-200\/70 {
    background-color: rgb(228 228 231 / 0.7);
}
.tw-bg-zinc-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}
.tw-bg-opacity-20 {
    --tw-bg-opacity: 0.2;
}
.tw-bg-opacity-60 {
    --tw-bg-opacity: 0.6;
}
.tw-bg-\[linear-gradient\(to_bottom\,_\#f4eafa\,_\#fff_20\%\)\] {
    background-image: linear-gradient(to bottom, #f4eafa, #fff 20%);
}
.tw-bg-\[linear-gradient\(to_top\,_\#f4eafa\,_\#fff_15\%\)\] {
    background-image: linear-gradient(to top, #f4eafa, #fff 15%);
}
.tw-bg-gradient-cinematic-radial {
    background-image: radial-gradient(50% 53% at 50% 50%, #480088 0%, #000A1A 100%);
}
.tw-bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}
.tw-bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}
.tw-bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
}
.tw-bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.tw-bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
}
.tw-bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}
.tw-bg-none {
    background-image: none;
}
.tw-from-\[\#EB7BFF\] {
    --tw-gradient-from: #EB7BFF var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(235 123 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-amber-300\/80 {
    --tw-gradient-from: rgb(252 211 77 / 0.8) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-black {
    --tw-gradient-from: #000 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-black\/100 {
    --tw-gradient-from: rgb(0 0 0 / 1) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-black\/90 {
    --tw-gradient-from: rgb(0 0 0 / 0.9) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-blue-200 {
    --tw-gradient-from: #bfdbfe var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-gray-700\/20 {
    --tw-gradient-from: rgb(55 65 81 / 0.2) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-gray-800 {
    --tw-gradient-from: #1f2937 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-orange-500 {
    --tw-gradient-from: #f97316 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-pink-100 {
    --tw-gradient-from: #fce7f3 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-pink-300\/90 {
    --tw-gradient-from: rgb(249 168 212 / 0.9) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-pink-500 {
    --tw-gradient-from: #ec4899 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-pink-600\/20 {
    --tw-gradient-from: rgb(219 39 119 / 0.2) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-purple-100 {
    --tw-gradient-from: #f3e8ff var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-purple-300 {
    --tw-gradient-from: #d8b4fe var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-purple-50 {
    --tw-gradient-from: #faf5ff var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-purple-600 {
    --tw-gradient-from: #9333ea var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-purple-600\/20 {
    --tw-gradient-from: rgb(147 51 234 / 0.2) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-purple-700 {
    --tw-gradient-from: #7e22ce var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-purple-900\/70 {
    --tw-gradient-from: rgb(88 28 135 / 0.7) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-transparent {
    --tw-gradient-from: transparent var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-violet-950 {
    --tw-gradient-from: #2e1065 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-white {
    --tw-gradient-from: #fff var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-from-yellow-200 {
    --tw-gradient-from: #fef08a var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.tw-via-\[\#72CDFD\] {
    --tw-gradient-to: rgb(114 205 253 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #72CDFD var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.tw-via-\[\#7EB7FF\] {
    --tw-gradient-to: rgb(126 183 255 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #7EB7FF var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.tw-via-\[\#A3AEFE\] {
    --tw-gradient-to: rgb(163 174 254 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #A3AEFE var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.tw-via-\[\#C5ADFF\] {
    --tw-gradient-to: rgb(197 173 255 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #C5ADFF var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.tw-via-\[\#D799FF\] {
    --tw-gradient-to: rgb(215 153 255 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #D799FF var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.tw-via-amber-400 {
    --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24 var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.tw-via-black\/30 {
    --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.tw-via-pink-500 {
    --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899 var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.tw-to-\[\#4BF2F6\] {
    --tw-gradient-to: #4BF2F6 var(--tw-gradient-to-position);
}
.tw-to-amber-400 {
    --tw-gradient-to: #fbbf24 var(--tw-gradient-to-position);
}
.tw-to-black {
    --tw-gradient-to: #000 var(--tw-gradient-to-position);
}
.tw-to-black\/20 {
    --tw-gradient-to: rgb(0 0 0 / 0.2) var(--tw-gradient-to-position);
}
.tw-to-black\/90 {
    --tw-gradient-to: rgb(0 0 0 / 0.9) var(--tw-gradient-to-position);
}
.tw-to-cyan-200 {
    --tw-gradient-to: #a5f3fc var(--tw-gradient-to-position);
}
.tw-to-gray-200 {
    --tw-gradient-to: #e5e7eb var(--tw-gradient-to-position);
}
.tw-to-indigo-700 {
    --tw-gradient-to: #4338ca var(--tw-gradient-to-position);
}
.tw-to-orange-500 {
    --tw-gradient-to: #f97316 var(--tw-gradient-to-position);
}
.tw-to-purple-100 {
    --tw-gradient-to: #f3e8ff var(--tw-gradient-to-position);
}
.tw-to-purple-400\/40 {
    --tw-gradient-to: rgb(192 132 252 / 0.4) var(--tw-gradient-to-position);
}
.tw-to-purple-500 {
    --tw-gradient-to: #a855f7 var(--tw-gradient-to-position);
}
.tw-to-purple-600 {
    --tw-gradient-to: #9333ea var(--tw-gradient-to-position);
}
.tw-to-purple-700 {
    --tw-gradient-to: #7e22ce var(--tw-gradient-to-position);
}
.tw-to-transparent {
    --tw-gradient-to: transparent var(--tw-gradient-to-position);
}
.tw-to-violet-800 {
    --tw-gradient-to: #5b21b6 var(--tw-gradient-to-position);
}
.tw-to-white {
    --tw-gradient-to: #fff var(--tw-gradient-to-position);
}
.tw-to-yellow-300 {
    --tw-gradient-to: #fde047 var(--tw-gradient-to-position);
}
.tw-bg-clip-text {
    -webkit-background-clip: text;
            background-clip: text;
}
.tw-bg-center {
    background-position: center;
}
.tw-bg-right-bottom {
    background-position: right bottom;
}
.tw-bg-no-repeat {
    background-repeat: no-repeat;
}
.tw-fill-current {
    fill: currentColor;
}
.tw-stroke-slate-50 {
    stroke: #f8fafc;
}
.tw-object-cover {
    -o-object-fit: cover;
       object-fit: cover;
}
.tw-p-0 {
    padding: 0px;
}
.tw-p-0\.5 {
    padding: 0.125rem;
}
.tw-p-1 {
    padding: 0.25rem;
}
.tw-p-1\.5 {
    padding: 0.375rem;
}
.tw-p-10 {
    padding: 2.5rem;
}
.tw-p-12 {
    padding: 3rem;
}
.tw-p-2 {
    padding: 0.5rem;
}
.tw-p-2\.5 {
    padding: 0.625rem;
}
.tw-p-3 {
    padding: 0.75rem;
}
.tw-p-4 {
    padding: 1rem;
}
.tw-p-5 {
    padding: 1.25rem;
}
.tw-p-6 {
    padding: 1.5rem;
}
.tw-p-8 {
    padding: 2rem;
}
.tw-px-0 {
    padding-left: 0px;
    padding-right: 0px;
}
.tw-px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
}
.tw-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.tw-px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
}
.tw-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
.tw-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
}
.tw-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.tw-px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}
.tw-px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
}
.tw-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.tw-px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
}
.tw-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.tw-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.tw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.tw-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}
.tw-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}
.tw-py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}
.tw-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.tw-py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}
.tw-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
.tw-py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.tw-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.tw-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.tw-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.tw-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.tw-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}
.tw-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.tw-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.tw-pb-0 {
    padding-bottom: 0px;
}
.tw-pb-1 {
    padding-bottom: 0.25rem;
}
.tw-pb-10 {
    padding-bottom: 2.5rem;
}
.tw-pb-16 {
    padding-bottom: 4rem;
}
.tw-pb-2 {
    padding-bottom: 0.5rem;
}
.tw-pb-20 {
    padding-bottom: 5rem;
}
.tw-pb-3 {
    padding-bottom: 0.75rem;
}
.tw-pb-4 {
    padding-bottom: 1rem;
}
.tw-pb-5 {
    padding-bottom: 1.25rem;
}
.tw-pb-6 {
    padding-bottom: 1.5rem;
}
.tw-pb-8 {
    padding-bottom: 2rem;
}
.tw-pl-0 {
    padding-left: 0px;
}
.tw-pl-1 {
    padding-left: 0.25rem;
}
.tw-pl-10 {
    padding-left: 2.5rem;
}
.tw-pl-14 {
    padding-left: 3.5rem;
}
.tw-pl-16 {
    padding-left: 4rem;
}
.tw-pl-2 {
    padding-left: 0.5rem;
}
.tw-pl-3 {
    padding-left: 0.75rem;
}
.tw-pl-4 {
    padding-left: 1rem;
}
.tw-pl-5 {
    padding-left: 1.25rem;
}
.tw-pl-8 {
    padding-left: 2rem;
}
.tw-pr-0 {
    padding-right: 0px;
}
.tw-pr-1 {
    padding-right: 0.25rem;
}
.tw-pr-2 {
    padding-right: 0.5rem;
}
.tw-pr-3 {
    padding-right: 0.75rem;
}
.tw-pr-4 {
    padding-right: 1rem;
}
.tw-pr-5 {
    padding-right: 1.25rem;
}
.tw-pr-6 {
    padding-right: 1.5rem;
}
.tw-pt-0 {
    padding-top: 0px;
}
.tw-pt-0\.5 {
    padding-top: 0.125rem;
}
.tw-pt-12 {
    padding-top: 3rem;
}
.tw-pt-14 {
    padding-top: 3.5rem;
}
.tw-pt-16 {
    padding-top: 4rem;
}
.tw-pt-2 {
    padding-top: 0.5rem;
}
.tw-pt-2\.5 {
    padding-top: 0.625rem;
}
.tw-pt-20 {
    padding-top: 5rem;
}
.tw-pt-24 {
    padding-top: 6rem;
}
.tw-pt-3 {
    padding-top: 0.75rem;
}
.tw-pt-4 {
    padding-top: 1rem;
}
.tw-pt-5 {
    padding-top: 1.25rem;
}
.tw-pt-6 {
    padding-top: 1.5rem;
}
.tw-pt-8 {
    padding-top: 2rem;
}
.tw-text-left {
    text-align: left;
}
.tw-text-center {
    text-align: center;
}
.tw-text-right {
    text-align: right;
}
.tw-align-middle {
    vertical-align: middle;
}
.tw-text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}
.tw-text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.tw-text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}
.tw-text-5xl {
    font-size: 3rem;
    line-height: 1;
}
.tw-text-6xl {
    font-size: 3.75rem;
    line-height: 1;
}
.tw-text-7xl {
    font-size: 4.5rem;
    line-height: 1;
}
.tw-text-\[10px\] {
    font-size: 10px;
}
.tw-text-\[11px\] {
    font-size: 11px;
}
.tw-text-\[500px\] {
    font-size: 500px;
}
.tw-text-\[9px\] {
    font-size: 9px;
}
.tw-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}
.tw-text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.tw-text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.tw-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.tw-text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.tw-font-black {
    font-weight: 900;
}
.tw-font-bold {
    font-weight: 700;
}
.tw-font-extrabold {
    font-weight: 800;
}
.tw-font-light {
    font-weight: 300;
}
.tw-font-medium {
    font-weight: 500;
}
.tw-font-normal {
    font-weight: 400;
}
.tw-font-semibold {
    font-weight: 600;
}
.tw-uppercase {
    text-transform: uppercase;
}
.tw-capitalize {
    text-transform: capitalize;
}
.tw-italic {
    font-style: italic;
}
.tw-normal-nums {
    font-variant-numeric: normal;
}
.tw-leading-10 {
    line-height: 2.5rem;
}
.tw-leading-5 {
    line-height: 1.25rem;
}
.tw-leading-6 {
    line-height: 1.5rem;
}
.tw-leading-loose {
    line-height: 2;
}
.tw-leading-none {
    line-height: 1;
}
.tw-leading-normal {
    line-height: 1.5;
}
.tw-leading-relaxed {
    line-height: 1.625;
}
.tw-leading-snug {
    line-height: 1.375;
}
.tw-leading-tight {
    line-height: 1.25;
}
.tw-tracking-normal {
    letter-spacing: 0em;
}
.tw-tracking-tight {
    letter-spacing: -0.025em;
}
.tw-tracking-tighter {
    letter-spacing: -0.05em;
}
.tw-tracking-wide {
    letter-spacing: 0.025em;
}
.tw-tracking-wider {
    letter-spacing: 0.05em;
}
.tw-tracking-widest {
    letter-spacing: 0.1em;
}
.tw-text-\[\#00C7F2\] {
    --tw-text-opacity: 1;
    color: rgb(0 199 242 / var(--tw-text-opacity));
}
.tw-text-\[\#1DB954\] {
    --tw-text-opacity: 1;
    color: rgb(29 185 84 / var(--tw-text-opacity));
}
.tw-text-\[\#FB2E5C\] {
    --tw-text-opacity: 1;
    color: rgb(251 46 92 / var(--tw-text-opacity));
}
.tw-text-\[\#FF0000\] {
    --tw-text-opacity: 1;
    color: rgb(255 0 0 / var(--tw-text-opacity));
}
.tw-text-\[\#FF7700\] {
    --tw-text-opacity: 1;
    color: rgb(255 119 0 / var(--tw-text-opacity));
}
.tw-text-amber-400 {
    --tw-text-opacity: 1;
    color: rgb(251 191 36 / var(--tw-text-opacity));
}
.tw-text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}
.tw-text-black\/30 {
    color: rgb(0 0 0 / 0.3);
}
.tw-text-black\/40 {
    color: rgb(0 0 0 / 0.4);
}
.tw-text-black\/50 {
    color: rgb(0 0 0 / 0.5);
}
.tw-text-black\/60 {
    color: rgb(0 0 0 / 0.6);
}
.tw-text-black\/70 {
    color: rgb(0 0 0 / 0.7);
}
.tw-text-blue-400 {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity));
}
.tw-text-blue-700 {
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity));
}
.tw-text-blue-800 {
    --tw-text-opacity: 1;
    color: rgb(30 64 175 / var(--tw-text-opacity));
}
.tw-text-cyan-600\/50 {
    color: rgb(8 145 178 / 0.5);
}
.tw-text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
}
.tw-text-gray-300 {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
}
.tw-text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
}
.tw-text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}
.tw-text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
}
.tw-text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}
.tw-text-gray-800 {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity));
}
.tw-text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity));
}
.tw-text-green-200 {
    --tw-text-opacity: 1;
    color: rgb(187 247 208 / var(--tw-text-opacity));
}
.tw-text-green-400 {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity));
}
.tw-text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity));
}
.tw-text-green-600 {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity));
}
.tw-text-green-700 {
    --tw-text-opacity: 1;
    color: rgb(21 128 61 / var(--tw-text-opacity));
}
.tw-text-green-800 {
    --tw-text-opacity: 1;
    color: rgb(22 101 52 / var(--tw-text-opacity));
}
.tw-text-green-800\/60 {
    color: rgb(22 101 52 / 0.6);
}
.tw-text-lime-300 {
    --tw-text-opacity: 1;
    color: rgb(190 242 100 / var(--tw-text-opacity));
}
.tw-text-muted-foreground {
    color: hsl(var(--muted-foreground));
}
.tw-text-orange-700 {
    --tw-text-opacity: 1;
    color: rgb(194 65 12 / var(--tw-text-opacity));
}
.tw-text-pink-500 {
    --tw-text-opacity: 1;
    color: rgb(236 72 153 / var(--tw-text-opacity));
}
.tw-text-pink-700 {
    --tw-text-opacity: 1;
    color: rgb(190 24 93 / var(--tw-text-opacity));
}
.tw-text-popover-foreground {
    color: hsl(var(--popover-foreground));
}
.tw-text-purple-300 {
    --tw-text-opacity: 1;
    color: rgb(216 180 254 / var(--tw-text-opacity));
}
.tw-text-purple-50 {
    --tw-text-opacity: 1;
    color: rgb(250 245 255 / var(--tw-text-opacity));
}
.tw-text-purple-500 {
    --tw-text-opacity: 1;
    color: rgb(168 85 247 / var(--tw-text-opacity));
}
.tw-text-purple-600 {
    --tw-text-opacity: 1;
    color: rgb(147 51 234 / var(--tw-text-opacity));
}
.tw-text-purple-700 {
    --tw-text-opacity: 1;
    color: rgb(126 34 206 / var(--tw-text-opacity));
}
.tw-text-purple-700\/50 {
    color: rgb(126 34 206 / 0.5);
}
.tw-text-purple-800 {
    --tw-text-opacity: 1;
    color: rgb(107 33 168 / var(--tw-text-opacity));
}
.tw-text-purple-900 {
    --tw-text-opacity: 1;
    color: rgb(88 28 135 / var(--tw-text-opacity));
}
.tw-text-red-400 {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity));
}
.tw-text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity));
}
.tw-text-red-600 {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity));
}
.tw-text-red-800 {
    --tw-text-opacity: 1;
    color: rgb(153 27 27 / var(--tw-text-opacity));
}
.tw-text-slate-950 {
    --tw-text-opacity: 1;
    color: rgb(2 6 23 / var(--tw-text-opacity));
}
.tw-text-transparent {
    color: transparent;
}
.tw-text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.tw-text-white\/10 {
    color: rgb(255 255 255 / 0.1);
}
.tw-text-white\/40 {
    color: rgb(255 255 255 / 0.4);
}
.tw-text-white\/50 {
    color: rgb(255 255 255 / 0.5);
}
.tw-text-white\/60 {
    color: rgb(255 255 255 / 0.6);
}
.tw-text-white\/70 {
    color: rgb(255 255 255 / 0.7);
}
.tw-text-white\/80 {
    color: rgb(255 255 255 / 0.8);
}
.tw-text-white\/90 {
    color: rgb(255 255 255 / 0.9);
}
.tw-text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgb(253 224 71 / var(--tw-text-opacity));
}
.tw-text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity));
}
.tw-text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgb(133 77 14 / var(--tw-text-opacity));
}
.tw-text-opacity-70 {
    --tw-text-opacity: 0.7;
}
.tw-underline {
    text-decoration-line: underline;
}
.tw-line-through {
    text-decoration-line: line-through;
}
.tw-accent-purple-600 {
    accent-color: #9333ea;
}
.tw-opacity-0 {
    opacity: 0;
}
.tw-opacity-100 {
    opacity: 1;
}
.tw-opacity-25 {
    opacity: 0.25;
}
.tw-opacity-30 {
    opacity: 0.3;
}
.tw-opacity-40 {
    opacity: 0.4;
}
.tw-opacity-50 {
    opacity: 0.5;
}
.tw-opacity-60 {
    opacity: 0.6;
}
.tw-opacity-70 {
    opacity: 0.7;
}
.tw-opacity-75 {
    opacity: 0.75;
}
.tw-opacity-80 {
    opacity: 0.8;
}
.tw-opacity-90 {
    opacity: 0.9;
}
.tw-shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tw-shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tw-shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tw-shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tw-shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tw-shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tw-shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tw-shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tw-shadow-black\/20 {
    --tw-shadow-color: rgb(0 0 0 / 0.2);
    --tw-shadow: var(--tw-shadow-colored);
}
.tw-shadow-pink-500\/20 {
    --tw-shadow-color: rgb(236 72 153 / 0.2);
    --tw-shadow: var(--tw-shadow-colored);
}
.tw-shadow-purple-500\/20 {
    --tw-shadow-color: rgb(168 85 247 / 0.2);
    --tw-shadow: var(--tw-shadow-colored);
}
.tw-outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.tw-ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.tw-ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.tw-ring-yellow-300\/50 {
    --tw-ring-color: rgb(253 224 71 / 0.5);
}
.tw-ring-offset-background {
    --tw-ring-offset-color: hsl(var(--background));
}
.tw-blur-\[2px\] {
    --tw-blur: blur(2px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.tw-blur-md {
    --tw-blur: blur(12px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.tw-blur-sm {
    --tw-blur: blur(4px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.tw-brightness-100 {
    --tw-brightness: brightness(1);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.tw-brightness-50 {
    --tw-brightness: brightness(.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.tw-brightness-75 {
    --tw-brightness: brightness(.75);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.tw-drop-shadow-\[0_1\.2px_3px_rgba\(0\,0\,0\,1\)\] {
    --tw-drop-shadow: drop-shadow(0 1.2px 3px rgba(0,0,0,1));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.tw-backdrop-blur {
    --tw-backdrop-blur: blur(8px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.tw-backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.tw-backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.tw-transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.tw-transition-\[width\] {
    transition-property: width;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.tw-transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.tw-transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.tw-transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.tw-transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.tw-duration-100 {
    transition-duration: 100ms;
}
.tw-duration-1000 {
    transition-duration: 1000ms;
}
.tw-duration-150 {
    transition-duration: 150ms;
}
.tw-duration-200 {
    transition-duration: 200ms;
}
.tw-duration-300 {
    transition-duration: 300ms;
}
.tw-duration-500 {
    transition-duration: 500ms;
}
.tw-ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.tw-ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.tw-ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
@keyframes enter {
    from {
        opacity: var(--tw-enter-opacity, 1);
        transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
    }
}
@keyframes exit {
    to {
        opacity: var(--tw-exit-opacity, 1);
        transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
    }
}
.tw-animate-in {
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}
.tw-fade-in {
    --tw-enter-opacity: 0;
}
.tw-duration-100 {
    animation-duration: 100ms;
}
.tw-duration-1000 {
    animation-duration: 1000ms;
}
.tw-duration-150 {
    animation-duration: 150ms;
}
.tw-duration-200 {
    animation-duration: 200ms;
}
.tw-duration-300 {
    animation-duration: 300ms;
}
.tw-duration-500 {
    animation-duration: 500ms;
}
.tw-ease-in {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.tw-ease-in-out {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.tw-ease-out {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.tw-scrollbar-thin::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track);
    border-radius: var(--scrollbar-track-radius);
}
.tw-scrollbar-thin::-webkit-scrollbar-track:hover {
    background-color: var(--scrollbar-track-hover, var(--scrollbar-track));
}
.tw-scrollbar-thin::-webkit-scrollbar-track:active {
    background-color: var(--scrollbar-track-active, var(--scrollbar-track-hover, var(--scrollbar-track)));
}
.tw-scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb);
    border-radius: var(--scrollbar-thumb-radius);
}
.tw-scrollbar-thin::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-hover, var(--scrollbar-thumb));
}
.tw-scrollbar-thin::-webkit-scrollbar-thumb:active {
    background-color: var(--scrollbar-thumb-active, var(--scrollbar-thumb-hover, var(--scrollbar-thumb)));
}
.tw-scrollbar-thin::-webkit-scrollbar-corner {
    background-color: var(--scrollbar-corner);
    border-radius: var(--scrollbar-corner-radius);
}
.tw-scrollbar-thin::-webkit-scrollbar-corner:hover {
    background-color: var(--scrollbar-corner-hover, var(--scrollbar-corner));
}
.tw-scrollbar-thin::-webkit-scrollbar-corner:active {
    background-color: var(--scrollbar-corner-active, var(--scrollbar-corner-hover, var(--scrollbar-corner)));
}
.tw-scrollbar-thin {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb, initial) var(--scrollbar-track, initial);
}
.tw-scrollbar-thin::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 8px;
}
.tw-scrollbar-track-gray-100 {
    --scrollbar-track: #f3f4f6 !important;
}
.tw-scrollbar-track-transparent {
    --scrollbar-track: transparent !important;
}
.tw-scrollbar-thumb-gray-200 {
    --scrollbar-thumb: #e5e7eb !important;
}
.tw-scrollbar-thumb-transparent {
    --scrollbar-thumb: transparent !important;
}
.no-scrollbar::-webkit-scrollbar {
    display: none;
  }
.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
.\[background-clip\:text\] {
    -webkit-background-clip: text;
            background-clip: text;
}
.\[background-position-x\:10px\] {
    background-position-x: 10px;
}
.\[background-position\:_10px\] {
    background-position: 10px;
}
.\[color\:transparent\] {
    color: transparent;
}
.\[filter\:drop-shadow\(0_0_1rem_black\)\] {
    filter: drop-shadow(0 0 1rem black);
}
.\[filter\:drop-shadow\(0_0_2\.5rem_black\)\] {
    filter: drop-shadow(0 0 2.5rem black);
}

body {
  min-width: 100%;
  overflow-y: auto !important;
  margin-right: 0 !important;
}

h3 {
  letter-spacing: -0.025em;
  margin-top: 0px;
  margin-bottom: 0px;
}

h2 {
  letter-spacing: -0.025em;
}

h1 {
  letter-spacing: -0.025em;
}

p {
  letter-spacing: -0.025em;
}

button,
textarea,
input,
dialog {
  outline: none;
}

p {
  padding: none;
  margin: none;
}

.main-container {
    margin-top: 2rem;
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 1420px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

a,
div {
  -webkit-tap-highlight-color: transparent;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-fade-in {
  animation: fadeIn 0.4s ease-in-out forwards;
}

.rainbow-button {
  position: relative;
  text-decoration: none;
  border: none;
  cursor: pointer;
  background-size: 100%;
  /* padding: 20px 50px; */
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  border-radius: 50px;
  overflow: hidden;
  width: 130px;
  height: 37px;
}

.rainbow-button:before {
  content: "";
  display: block;
  position: absolute;
  left: -10px;
  top: 50%;
  width: 150px;
  height: 100px;
  border-radius: 50px;
  background-image: conic-gradient(
    from 180deg,
    #fd004c,
    #fe9000,
    #fff020,
    #3edf4b,
    #3363ff,
    #b102b7,
    #fd004c
  );
  filter: blur(12px) brightness(0.9);
  transform: translateY(-50%) rotate(0);
  transition: transform 0.3s ease-in-out;
  /* transition: all 0.3s ease-in-out; */
}

@keyframes spinAnimation {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}

.rainbow-button.spin:before {
  animation: spinAnimation 1s linear infinite;
}

/* thead th {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
} */

table {
  border-collapse: collapse;
}

.placeholder\:tw-text-muted-foreground::-moz-placeholder {
    color: hsl(var(--muted-foreground));
}

.placeholder\:tw-text-muted-foreground::placeholder {
    color: hsl(var(--muted-foreground));
}

.tw-group:hover .group-hover\:tw-scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-group:hover .group-hover\:tw-scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-group:hover .group-hover\:tw-bg-neutral-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.tw-group:hover .group-hover\:tw-text-purple-700 {
    --tw-text-opacity: 1;
    color: rgb(126 34 206 / var(--tw-text-opacity));
}

.tw-group:focus .group-focus\:tw-text-purple-700 {
    --tw-text-opacity: 1;
    color: rgb(126 34 206 / var(--tw-text-opacity));
}

.data-\[disabled\]\:tw-pointer-events-none[data-disabled] {
    pointer-events: none;
}

.data-\[side\=bottom\]\:tw-translate-y-1[data-side=bottom] {
    --tw-translate-y: 0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[side\=left\]\:tw--translate-x-1[data-side=left] {
    --tw-translate-x: -0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[side\=right\]\:tw-translate-x-1[data-side=right] {
    --tw-translate-x: 0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[side\=top\]\:tw--translate-y-1[data-side=top] {
    --tw-translate-y: -0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[state\=checked\]\:tw-translate-x-6[data-state=checked] {
    --tw-translate-x: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[state\=unchecked\]\:tw-translate-x-0\.5[data-state=unchecked] {
    --tw-translate-x: 0.125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes tw-accordion-up {
    from {
        height: var(--radix-accordion-content-height);
    }
    to {
        height: 0;
    }
}

.data-\[state\=closed\]\:tw-animate-accordion-up[data-state=closed] {
    animation: tw-accordion-up 0.2s ease-out;
}

@keyframes tw-accordion-down {
    from {
        height: 0;
    }
    to {
        height: var(--radix-accordion-content-height);
    }
}

.data-\[state\=open\]\:tw-animate-accordion-down[data-state=open] {
    animation: tw-accordion-down 0.2s ease-out;
}

.data-\[active\]\:tw-bg-gray-100\/50[data-active] {
    background-color: rgb(243 244 246 / 0.5);
}

.data-\[state\=checked\]\:tw-bg-primary[data-state=checked] {
    background-color: hsl(var(--primary));
}

.data-\[state\=checked\]\:tw-bg-purple-700[data-state=checked] {
    --tw-bg-opacity: 1;
    background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

.data-\[state\=open\]\:tw-bg-gray-100\/50[data-state=open] {
    background-color: rgb(243 244 246 / 0.5);
}

.data-\[state\=selected\]\:tw-bg-gray-100[data-state=selected] {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.data-\[state\=unchecked\]\:tw-bg-input[data-state=unchecked] {
    background-color: hsl(var(--input));
}

.data-\[state\=unchecked\]\:tw-bg-zinc-200[data-state=unchecked] {
    --tw-bg-opacity: 1;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}

.data-\[state\=checked\]\:tw-bg-gradient-to-r[data-state=checked] {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.data-\[state\=checked\]\:tw-from-blue-300[data-state=checked] {
    --tw-gradient-from: #93c5fd var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.data-\[state\=checked\]\:tw-to-cyan-300[data-state=checked] {
    --tw-gradient-to: #67e8f9 var(--tw-gradient-to-position);
}

.data-\[state\=checked\]\:tw-text-purple-300[data-state=checked] {
    --tw-text-opacity: 1;
    color: rgb(216 180 254 / var(--tw-text-opacity));
}

.data-\[disabled\]\:tw-opacity-50[data-disabled] {
    opacity: 0.5;
}

.data-\[motion\^\=from-\]\:tw-animate-in[data-motion^=from-] {
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}

.data-\[state\=open\]\:tw-animate-in[data-state=open] {
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}

.data-\[state\=visible\]\:tw-animate-in[data-state=visible] {
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}

.data-\[motion\^\=to-\]\:tw-animate-out[data-motion^=to-] {
    animation-name: exit;
    animation-duration: 150ms;
    --tw-exit-opacity: initial;
    --tw-exit-scale: initial;
    --tw-exit-rotate: initial;
    --tw-exit-translate-x: initial;
    --tw-exit-translate-y: initial;
}

.data-\[state\=closed\]\:tw-animate-out[data-state=closed] {
    animation-name: exit;
    animation-duration: 150ms;
    --tw-exit-opacity: initial;
    --tw-exit-scale: initial;
    --tw-exit-rotate: initial;
    --tw-exit-translate-x: initial;
    --tw-exit-translate-y: initial;
}

.data-\[state\=hidden\]\:tw-animate-out[data-state=hidden] {
    animation-name: exit;
    animation-duration: 150ms;
    --tw-exit-opacity: initial;
    --tw-exit-scale: initial;
    --tw-exit-rotate: initial;
    --tw-exit-translate-x: initial;
    --tw-exit-translate-y: initial;
}

.data-\[motion\^\=from-\]\:tw-fade-in[data-motion^=from-] {
    --tw-enter-opacity: 0;
}

.data-\[motion\^\=to-\]\:tw-fade-out[data-motion^=to-] {
    --tw-exit-opacity: 0;
}

.data-\[state\=closed\]\:tw-fade-out-0[data-state=closed] {
    --tw-exit-opacity: 0;
}

.data-\[state\=hidden\]\:tw-fade-out[data-state=hidden] {
    --tw-exit-opacity: 0;
}

.data-\[state\=open\]\:tw-fade-in-0[data-state=open] {
    --tw-enter-opacity: 0;
}

.data-\[state\=visible\]\:tw-fade-in[data-state=visible] {
    --tw-enter-opacity: 0;
}

.data-\[state\=closed\]\:tw-zoom-out-95[data-state=closed] {
    --tw-exit-scale: .95;
}

.data-\[state\=open\]\:tw-zoom-in-90[data-state=open] {
    --tw-enter-scale: .9;
}

.data-\[state\=open\]\:tw-zoom-in-95[data-state=open] {
    --tw-enter-scale: .95;
}

.data-\[motion\=from-end\]\:tw-slide-in-from-right-52[data-motion=from-end] {
    --tw-enter-translate-x: 13rem;
}

.data-\[motion\=from-start\]\:tw-slide-in-from-left-52[data-motion=from-start] {
    --tw-enter-translate-x: -13rem;
}

.data-\[motion\=to-end\]\:tw-slide-out-to-right-52[data-motion=to-end] {
    --tw-exit-translate-x: 13rem;
}

.data-\[motion\=to-start\]\:tw-slide-out-to-left-52[data-motion=to-start] {
    --tw-exit-translate-x: -13rem;
}

.data-\[side\=bottom\]\:tw-slide-in-from-top-2[data-side=bottom] {
    --tw-enter-translate-y: -0.5rem;
}

.data-\[side\=left\]\:tw-slide-in-from-right-2[data-side=left] {
    --tw-enter-translate-x: 0.5rem;
}

.data-\[side\=right\]\:tw-slide-in-from-left-2[data-side=right] {
    --tw-enter-translate-x: -0.5rem;
}

.data-\[side\=top\]\:tw-slide-in-from-bottom-2[data-side=top] {
    --tw-enter-translate-y: 0.5rem;
}

.tw-group[data-state=open] .group-data-\[state\=open\]\:tw-rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:tw-translate-y-\[-2px\]:hover {
    --tw-translate-y: -2px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:tw-scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:tw-scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:tw-scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:tw-scale-\[1\.02\]:hover {
    --tw-scale-x: 1.02;
    --tw-scale-y: 1.02;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:tw-transform:hover {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:tw-rounded-full:hover {
    border-radius: 9999px;
}

.hover\:tw-border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.hover\:tw-border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.hover\:tw-border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.hover\:tw-bg-black\/50:hover {
    background-color: rgb(0 0 0 / 0.5);
}

.hover\:tw-bg-black\/90:hover {
    background-color: rgb(0 0 0 / 0.9);
}

.hover\:tw-bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-100\/10:hover {
    background-color: rgb(243 244 246 / 0.1);
}

.hover\:tw-bg-gray-100\/50:hover {
    background-color: rgb(243 244 246 / 0.5);
}

.hover\:tw-bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-200\/60:hover {
    background-color: rgb(229 231 235 / 0.6);
}

.hover\:tw-bg-gray-200\/70:hover {
    background-color: rgb(229 231 235 / 0.7);
}

.hover\:tw-bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:tw-bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.hover\:tw-bg-neutral-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.hover\:tw-bg-neutral-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.hover\:tw-bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

.hover\:tw-bg-purple-100\/50:hover {
    background-color: rgb(243 232 255 / 0.5);
}

.hover\:tw-bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}

.hover\:tw-bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}

.hover\:tw-bg-purple-300\/10:hover {
    background-color: rgb(216 180 254 / 0.1);
}

.hover\:tw-bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}

.hover\:tw-bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}

.hover\:tw-bg-purple-700\/10:hover {
    background-color: rgb(126 34 206 / 0.1);
}

.hover\:tw-bg-purple-700\/30:hover {
    background-color: rgb(126 34 206 / 0.3);
}

.hover\:tw-bg-purple-700\/60:hover {
    background-color: rgb(126 34 206 / 0.6);
}

.hover\:tw-bg-purple-700\/80:hover {
    background-color: rgb(126 34 206 / 0.8);
}

.hover\:tw-bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}

.hover\:tw-bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.hover\:tw-bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

.hover\:tw-bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.hover\:tw-bg-violet-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}

.hover\:tw-bg-white\/10:hover {
    background-color: rgb(255 255 255 / 0.1);
}

.hover\:tw-bg-white\/20:hover {
    background-color: rgb(255 255 255 / 0.2);
}

.hover\:tw-bg-white\/90:hover {
    background-color: rgb(255 255 255 / 0.9);
}

.hover\:tw-bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
}

.hover\:tw-from-purple-100:hover {
    --tw-gradient-from: #f3e8ff var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.hover\:tw-from-yellow-300:hover {
    --tw-gradient-from: #fde047 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.hover\:tw-via-amber-400:hover {
    --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.hover\:tw-to-purple-200:hover {
    --tw-gradient-to: #e9d5ff var(--tw-gradient-to-position);
}

.hover\:tw-to-yellow-500:hover {
    --tw-gradient-to: #eab308 var(--tw-gradient-to-position);
}

.hover\:tw-text-black\/40:hover {
    color: rgb(0 0 0 / 0.4);
}

.hover\:tw-text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgb(126 34 206 / var(--tw-text-opacity));
}

.hover\:tw-text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgb(107 33 168 / var(--tw-text-opacity));
}

.hover\:tw-text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgb(88 28 135 / var(--tw-text-opacity));
}

.hover\:tw-text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity));
}

.hover\:tw-text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity));
}

.hover\:tw-underline:hover {
    text-decoration-line: underline;
}

.hover\:tw-no-underline:hover {
    text-decoration-line: none;
}

.hover\:tw-opacity-100:hover {
    opacity: 1;
}

.hover\:tw-opacity-80:hover {
    opacity: 0.8;
}

.hover\:tw-opacity-90:hover {
    opacity: 0.9;
}

.hover\:tw-shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:tw-shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:tw-shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:tw-shadow-yellow-400\/30:hover {
    --tw-shadow-color: rgb(250 204 21 / 0.3);
    --tw-shadow: var(--tw-shadow-colored);
}

.hover\:tw-ring-yellow-300\/70:hover {
    --tw-ring-color: rgb(253 224 71 / 0.7);
}

.focus\:tw-border-2:focus {
    border-width: 2px;
}

.focus\:tw-border-solid:focus {
    border-style: solid;
}

.focus\:tw-border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.focus\:tw-border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.focus\:tw-border-transparent:focus {
    border-color: transparent;
}

.focus\:tw-bg-accent:focus {
    background-color: hsl(var(--accent));
}

.focus\:tw-bg-neutral-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.focus\:tw-bg-neutral-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.focus\:tw-bg-purple-700\/10:focus {
    background-color: rgb(126 34 206 / 0.1);
}

.focus\:tw-text-accent-foreground:focus {
    color: hsl(var(--accent-foreground));
}

.focus\:tw-outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.focus\:tw-ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:tw-ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(216 180 254 / var(--tw-ring-opacity));
}

.focus\:tw-ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity));
}

.focus\:tw-ring-ring:focus {
    --tw-ring-color: hsl(var(--ring));
}

.focus\:tw-ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
}

.focus\:tw-ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
}

.focus-visible\:tw-outline-none:focus-visible {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.focus-visible\:tw-ring-1:focus-visible {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:tw-ring-2:focus-visible {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:tw-ring-ring:focus-visible {
    --tw-ring-color: hsl(var(--ring));
}

.focus-visible\:tw-ring-offset-2:focus-visible {
    --tw-ring-offset-width: 2px;
}

.focus-visible\:tw-ring-offset-background:focus-visible {
    --tw-ring-offset-color: hsl(var(--background));
}

.disabled\:tw-pointer-events-none:disabled {
    pointer-events: none;
}

.disabled\:tw-cursor-default:disabled {
    cursor: default;
}

.disabled\:tw-cursor-not-allowed:disabled {
    cursor: not-allowed;
}

.disabled\:tw-border-none:disabled {
    border-style: none;
}

.disabled\:tw-bg-purple-300:disabled {
    --tw-bg-opacity: 1;
    background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}

.disabled\:tw-opacity-50:disabled {
    opacity: 0.5;
}

@media (min-width: 640px) {
    .sm\:tw-absolute {
        position: absolute;
    }
    .sm\:-tw-bottom-\[5px\] {
        bottom: -5px;
    }
    .sm\:tw-col-span-2 {
        grid-column: span 2 / span 2;
    }
    .sm\:tw-my-4 {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .sm\:tw-mb-0 {
        margin-bottom: 0px;
    }
    .sm\:tw-mb-6 {
        margin-bottom: 1.5rem;
    }
    .sm\:tw-mt-auto {
        margin-top: auto;
    }
    .sm\:tw-block {
        display: block;
    }
    .sm\:tw-inline-block {
        display: inline-block;
    }
    .sm\:tw-flex {
        display: flex;
    }
    .sm\:tw-grid {
        display: grid;
    }
    .sm\:tw-hidden {
        display: none;
    }
    .sm\:tw-h-40 {
        height: 10rem;
    }
    .sm\:tw-h-52 {
        height: 13rem;
    }
    .sm\:tw-h-72 {
        height: 18rem;
    }
    .sm\:tw-h-auto {
        height: auto;
    }
    .sm\:tw-w-1\/2 {
        width: 50%;
    }
    .sm\:tw-w-1\/3 {
        width: 33.333333%;
    }
    .sm\:tw-w-2\/5 {
        width: 40%;
    }
    .sm\:tw-w-3\/5 {
        width: 60%;
    }
    .sm\:tw-w-\[33\%\] {
        width: 33%;
    }
    .sm\:tw-min-w-\[40rem\] {
        min-width: 40rem;
    }
    .sm\:tw-min-w-\[454px\] {
        min-width: 454px;
    }
    .sm\:tw-max-w-\[300px\] {
        max-width: 300px;
    }
    .sm\:tw-max-w-md {
        max-width: 28rem;
    }
    .sm\:tw-flex-none {
        flex: none;
    }
    .sm\:tw-grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .sm\:tw-flex-row {
        flex-direction: row;
    }
    .sm\:tw-flex-row-reverse {
        flex-direction: row-reverse;
    }
    .sm\:tw-flex-col {
        flex-direction: column;
    }
    .sm\:tw-flex-wrap {
        flex-wrap: wrap;
    }
    .sm\:tw-justify-end {
        justify-content: flex-end;
    }
    .sm\:tw-justify-center {
        justify-content: center;
    }
    .sm\:tw-justify-between {
        justify-content: space-between;
    }
    .sm\:tw-gap-12 {
        gap: 3rem;
    }
    .sm\:tw-gap-2 {
        gap: 0.5rem;
    }
    .sm\:tw-gap-20 {
        gap: 5rem;
    }
    .sm\:tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.5rem * var(--tw-space-x-reverse));
        margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
    }
    .sm\:tw-overflow-x-hidden {
        overflow-x: hidden;
    }
    .sm\:tw-rounded-3xl {
        border-radius: 1.5rem;
    }
    .sm\:tw-p-4 {
        padding: 1rem;
    }
    .sm\:tw-px-3 {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
    .sm\:tw-pb-4 {
        padding-bottom: 1rem;
    }
    .sm\:tw-pl-48 {
        padding-left: 12rem;
    }
    .sm\:tw-pt-6 {
        padding-top: 1.5rem;
    }
    .sm\:tw-text-left {
        text-align: left;
    }
    .sm\:tw-text-2xl {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .sm\:tw-text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem;
    }
    .sm\:tw-text-6xl {
        font-size: 3.75rem;
        line-height: 1;
    }
    .sm\:tw-text-base {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

@media (min-width: 768px) {
    .md\:tw-absolute {
        position: absolute;
    }
    .md\:tw-relative {
        position: relative;
    }
    .md\:tw--right-1 {
        right: -0.25rem;
    }
    .md\:tw--top-1 {
        top: -0.25rem;
    }
    .md\:tw-left-4 {
        left: 1rem;
    }
    .md\:tw-left-auto {
        left: auto;
    }
    .md\:tw-right-0 {
        right: 0px;
    }
    .md\:tw-top-0 {
        top: 0px;
    }
    .md\:tw-top-4 {
        top: 1rem;
    }
    .md\:tw-top-\[330px\] {
        top: 330px;
    }
    .md\:tw-top-\[65px\] {
        top: 65px;
    }
    .md\:tw-top-auto {
        top: auto;
    }
    .md\:tw-col-span-1 {
        grid-column: span 1 / span 1;
    }
    .md\:tw-col-span-3 {
        grid-column: span 3 / span 3;
    }
    .md\:tw-float-right {
        float: right;
    }
    .md\:tw-mx-0 {
        margin-left: 0px;
        margin-right: 0px;
    }
    .md\:tw-mx-6 {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
    .md\:-tw-mt-8 {
        margin-top: -2rem;
    }
    .md\:tw--ml-6 {
        margin-left: -1.5rem;
    }
    .md\:tw--mr-6 {
        margin-right: -1.5rem;
    }
    .md\:tw-mb-0 {
        margin-bottom: 0px;
    }
    .md\:tw-mb-1 {
        margin-bottom: 0.25rem;
    }
    .md\:tw-mb-2 {
        margin-bottom: 0.5rem;
    }
    .md\:tw-mb-4 {
        margin-bottom: 1rem;
    }
    .md\:tw-mb-6 {
        margin-bottom: 1.5rem;
    }
    .md\:tw-ml-3 {
        margin-left: 0.75rem;
    }
    .md\:tw-mr-5 {
        margin-right: 1.25rem;
    }
    .md\:tw-mt-0 {
        margin-top: 0px;
    }
    .md\:tw-mt-10 {
        margin-top: 2.5rem;
    }
    .md\:tw-mt-2 {
        margin-top: 0.5rem;
    }
    .md\:tw-mt-20 {
        margin-top: 5rem;
    }
    .md\:tw-mt-3 {
        margin-top: 0.75rem;
    }
    .md\:tw-mt-4 {
        margin-top: 1rem;
    }
    .md\:tw-mt-5 {
        margin-top: 1.25rem;
    }
    .md\:tw-mt-6 {
        margin-top: 1.5rem;
    }
    .md\:tw-mt-8 {
        margin-top: 2rem;
    }
    .md\:tw-mt-auto {
        margin-top: auto;
    }
    .md\:tw-block {
        display: block;
    }
    .md\:tw-flex {
        display: flex;
    }
    .md\:tw-hidden {
        display: none;
    }
    .md\:tw-size-14 {
        width: 3.5rem;
        height: 3.5rem;
    }
    .md\:tw-size-48 {
        width: 12rem;
        height: 12rem;
    }
    .md\:tw-h-10 {
        height: 2.5rem;
    }
    .md\:tw-h-11 {
        height: 2.75rem;
    }
    .md\:tw-h-14 {
        height: 3.5rem;
    }
    .md\:tw-h-20 {
        height: 5rem;
    }
    .md\:tw-h-3 {
        height: 0.75rem;
    }
    .md\:tw-h-52 {
        height: 13rem;
    }
    .md\:tw-h-60 {
        height: 15rem;
    }
    .md\:tw-h-72 {
        height: 18rem;
    }
    .md\:tw-h-80 {
        height: 20rem;
    }
    .md\:tw-h-max {
        height: -moz-max-content;
        height: max-content;
    }
    .md\:tw-h-screen {
        height: 100vh;
    }
    .md\:tw-max-h-\[500px\] {
        max-height: 500px;
    }
    .md\:tw-min-h-14 {
        min-height: 3.5rem;
    }
    .md\:tw-min-h-16 {
        min-height: 4rem;
    }
    .md\:tw-min-h-max {
        min-height: -moz-max-content;
        min-height: max-content;
    }
    .md\:tw-w-1\/2 {
        width: 50%;
    }
    .md\:tw-w-1\/3 {
        width: 33.333333%;
    }
    .md\:tw-w-1\/4 {
        width: 25%;
    }
    .md\:tw-w-2\/3 {
        width: 66.666667%;
    }
    .md\:tw-w-3 {
        width: 0.75rem;
    }
    .md\:tw-w-96 {
        width: 24rem;
    }
    .md\:tw-w-\[30rem\] {
        width: 30rem;
    }
    .md\:tw-w-\[35rem\] {
        width: 35rem;
    }
    .md\:tw-w-\[400px\] {
        width: 400px;
    }
    .md\:tw-w-\[470px\] {
        width: 470px;
    }
    .md\:tw-w-\[550px\] {
        width: 550px;
    }
    .md\:tw-w-\[55rem\] {
        width: 55rem;
    }
    .md\:tw-w-\[650px\] {
        width: 650px;
    }
    .md\:tw-w-\[750px\] {
        width: 750px;
    }
    .md\:tw-w-\[790px\] {
        width: 790px;
    }
    .md\:tw-w-\[var\(--radix-navigation-menu-viewport-width\)\] {
        width: var(--radix-navigation-menu-viewport-width);
    }
    .md\:tw-w-auto {
        width: auto;
    }
    .md\:tw-w-full {
        width: 100%;
    }
    .md\:tw-w-max {
        width: -moz-max-content;
        width: max-content;
    }
    .md\:tw-min-w-14 {
        min-width: 3.5rem;
    }
    .md\:tw-min-w-16 {
        min-width: 4rem;
    }
    .md\:tw-min-w-96 {
        min-width: 24rem;
    }
    .md\:tw-min-w-\[400px\] {
        min-width: 400px;
    }
    .md\:tw-min-w-\[450px\] {
        min-width: 450px;
    }
    .md\:tw-min-w-\[600px\] {
        min-width: 600px;
    }
    .md\:tw-max-w-96 {
        max-width: 24rem;
    }
    .md\:tw-max-w-\[440px\] {
        max-width: 440px;
    }
    .md\:tw-max-w-full {
        max-width: 100%;
    }
    .md\:tw-max-w-lg {
        max-width: 32rem;
    }
    .md\:tw-max-w-max {
        max-width: -moz-max-content;
        max-width: max-content;
    }
    .md\:tw-max-w-md {
        max-width: 28rem;
    }
    .md\:tw-max-w-sm {
        max-width: 24rem;
    }
    .md\:tw-translate-x-0 {
        --tw-translate-x: 0px;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .md\:tw-scale-100 {
        --tw-scale-x: 1;
        --tw-scale-y: 1;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .md\:tw-grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .md\:tw-grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .md\:tw-grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .md\:tw-flex-row {
        flex-direction: row;
    }
    .md\:tw-flex-col {
        flex-direction: column;
    }
    .md\:tw-flex-nowrap {
        flex-wrap: nowrap;
    }
    .md\:tw-items-start {
        align-items: flex-start;
    }
    .md\:tw-items-center {
        align-items: center;
    }
    .md\:tw-justify-center {
        justify-content: center;
    }
    .md\:tw-gap-0 {
        gap: 0px;
    }
    .md\:tw-gap-2 {
        gap: 0.5rem;
    }
    .md\:tw-gap-4 {
        gap: 1rem;
    }
    .md\:tw-gap-5 {
        gap: 1.25rem;
    }
    .md\:tw-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .md\:tw-rounded-2xl {
        border-radius: 1rem;
    }
    .md\:tw-rounded-3xl {
        border-radius: 1.5rem;
    }
    .md\:tw-rounded-5xl {
        border-radius: 44px;
    }
    .md\:tw-rounded-full {
        border-radius: 9999px;
    }
    .md\:tw-rounded-xl {
        border-radius: 0.75rem;
    }
    .md\:tw-border {
        border-width: 1px;
    }
    .md\:tw-border-solid {
        border-style: solid;
    }
    .md\:tw-p-0 {
        padding: 0px;
    }
    .md\:tw-p-10 {
        padding: 2.5rem;
    }
    .md\:tw-p-2 {
        padding: 0.5rem;
    }
    .md\:tw-p-24 {
        padding: 6rem;
    }
    .md\:tw-p-3 {
        padding: 0.75rem;
    }
    .md\:tw-p-4 {
        padding: 1rem;
    }
    .md\:tw-p-5 {
        padding: 1.25rem;
    }
    .md\:tw-p-6 {
        padding: 1.5rem;
    }
    .md\:tw-p-8 {
        padding: 2rem;
    }
    .md\:tw-px-0 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .md\:tw-px-2 {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .md\:tw-px-20 {
        padding-left: 5rem;
        padding-right: 5rem;
    }
    .md\:tw-px-28 {
        padding-left: 7rem;
        padding-right: 7rem;
    }
    .md\:tw-px-3 {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
    .md\:tw-px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .md\:tw-px-5 {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
    .md\:tw-px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .md\:tw-px-8 {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .md\:tw-py-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .md\:tw-py-10 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
    .md\:tw-py-16 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .md\:tw-py-2 {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .md\:tw-py-8 {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .md\:tw-pb-0 {
        padding-bottom: 0px;
    }
    .md\:tw-pb-5 {
        padding-bottom: 1.25rem;
    }
    .md\:tw-pb-6 {
        padding-bottom: 1.5rem;
    }
    .md\:tw-pr-6 {
        padding-right: 1.5rem;
    }
    .md\:tw-pt-10 {
        padding-top: 2.5rem;
    }
    .md\:tw-pt-16 {
        padding-top: 4rem;
    }
    .md\:tw-pt-2 {
        padding-top: 0.5rem;
    }
    .md\:tw-pt-28 {
        padding-top: 7rem;
    }
    .md\:tw-pt-4 {
        padding-top: 1rem;
    }
    .md\:tw-text-left {
        text-align: left;
    }
    .md\:tw-text-center {
        text-align: center;
    }
    .md\:tw-text-2xl {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .md\:tw-text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem;
    }
    .md\:tw-text-4xl {
        font-size: 2.25rem;
        line-height: 2.5rem;
    }
    .md\:tw-text-5xl {
        font-size: 3rem;
        line-height: 1;
    }
    .md\:tw-text-6xl {
        font-size: 3.75rem;
        line-height: 1;
    }
    .md\:tw-text-7xl {
        font-size: 4.5rem;
        line-height: 1;
    }
    .md\:tw-text-base {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .md\:tw-text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
    .md\:tw-text-sm {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    .md\:tw-text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
    .md\:tw-text-xs {
        font-size: 0.75rem;
        line-height: 1rem;
    }
    .md\:tw-opacity-100 {
        opacity: 1;
    }
    .md\:tw-opacity-40 {
        opacity: 0.4;
    }
    .md\:tw-shadow-none {
        --tw-shadow: 0 0 #0000;
        --tw-shadow-colored: 0 0 #0000;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .md\:tw-blur-none {
        --tw-blur: blur(0);
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    }
    .md\:\[filter\:drop-shadow\(0_0_1\.5rem_\#FFFFFF80\)\] {
        filter: drop-shadow(0 0 1.5rem #FFFFFF80);
    }
}

@media (min-width: 1024px) {
    .lg\:tw-grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .lg\:tw-grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media (min-width: 1280px) {
    .xl\:tw-grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media (prefers-color-scheme: dark) {
    .dark\:tw-border-slate-800 {
        --tw-border-opacity: 1;
        border-color: rgb(30 41 59 / var(--tw-border-opacity));
    }
    .dark\:tw-bg-slate-950 {
        --tw-bg-opacity: 1;
        background-color: rgb(2 6 23 / var(--tw-bg-opacity));
    }
    .dark\:tw-text-slate-50 {
        --tw-text-opacity: 1;
        color: rgb(248 250 252 / var(--tw-text-opacity));
    }
}

.\[\&\:has\(\[role\=checkbox\]\)\]\:tw-pr-0:has([role=checkbox]) {
    padding-right: 0px;
}

.\[\&\>\[role\=checkbox\]\]\:tw-translate-y-\[2px\]>[role=checkbox] {
    --tw-translate-y: 2px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\>span\]\:tw-line-clamp-1>span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.\[\&\[data-state\=open\]\>svg\]\:tw-rotate-180[data-state=open]>svg {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&_tr\:last-child\]\:tw-border-0 tr:last-child {
    border-width: 0px;
}

.\[\&_tr\]\:tw-border-y tr {
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.\[\&_tr\]\:tw-border-solid tr {
    border-style: solid;
}

.\[\&_tr\]\:tw-border-black\/10 tr {
    border-color: rgb(0 0 0 / 0.1);
}

:root {
  --bg-color-light: #ffffff;
  --bg-color-dark: #1a1a1a;
  --primary: #782dc8;
  --cubic: cubic-bezier(0.4, 0, 0.2, 1);
  --opacity: 0.6; /* Define the opacity variable here */
}

.light-theme {
  --bg-color: var(--bg-color-light) !important;
  --raised-bg: white;
  --text-color: black;
}

.dark-theme {
  --bg-color: var(--bg-color-dark);
  --raised-bg: #151619;
  --text-color: white;
  font-weight: 500;
}

.blur {
  background: linear-gradient(
    90deg,
    #44ff9a -0.55%,
    #44b0ff 22.86%,
    #8b44ff 48.36%,
    #f64 73.33%,
    #ebff70 99.34%
  );
  filter: blur(16px);
  top: 0;
  opacity: 0.4;
  z-index: 100;
  position: fixed;
  transform: rotate(180deg);
}

.Toastify__toast {
  z-index: 1000000000;
}

.swagger-ui {
  width: 100%;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0) rotate(-1deg);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0) rotate(1deg);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0) rotate(-0.5deg);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0) rotate(0.5deg);
  }
}

.shake-on-load {
  animation: shake 0.72s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.nothing-found-container {
  width: 100%;
  font-size: 30px;
  margin-top: 30px;
  font-weight: 400;
  text-align: center;
  animation: fadeIn 0.3s var(--cubic) forwards;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-btn {
  content: "";
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
  color: white;
  font-weight: 700;
  padding-left: 20px;
  width: 100%;
  padding-right: 20px;
  background: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  animation: gradient 3s ease infinite alternate;
  background-size: 300% 300%;
  max-width: 450px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-weight: 500;
  scroll-behavior: smooth;
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}

#ck-app p {
  border-radius: 32px !important;
}

#ck-app button {
  border-radius: 32px !important;
  font-weight: 600 !important;
}

.loading {
  opacity: 0.5;
}

#ck-app div {
  z-index: 1000 !important;
}

#ck-cf-modal {
  border-radius: 20px !important;
  z-index: 12000 !important;
}

#ck-theme-pre-section {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  width: -moz-max-content !important;
  width: max-content !important;
  min-width: 300px !important;
}

html {
  overflow-y: scroll;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.gradient-text {
  background: linear-gradient(to right, pink, purple);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}

.gradient-text2 {
  background: linear-gradient(-65deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.flex-gap-3 {
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 3px;
}
.flex-gap-10 {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.flex-gap-5 {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
}
.error-message {
  background-color: #f31260;
  padding: 10px;
  border-radius: 100px;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: 800;
  padding-top: 15px;
  padding-bottom: 15px;
}

.loader-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.loader-wrapper-models {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  opacity: 0;
  transition: all 0.14s ease;
  transform: scale(0);
}
.loader-wrapper-models-visible {
  opacity: 1;
  transform: scale(1);
}
.container-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 76px);
  width: 100%;
  flex-direction: column;
}

.ps-sidebar-container {
  /* border-radius: 16px; */
  /* background-color: transparent; */
}

.ps-menu-button {
  font-size: 17px;
  font-weight: 600;
}

.main {
  justify-content: center;
  margin-top: 30px;

  display: flex;
  height: 100%;
  max-width: 1440px;
  padding-inline: 1.5rem;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  /* padding-left: 250px !important; */
}

.terms-and-conditions {
  display: block;
}

.terms-and-conditions a {
  font-weight: bold;
  text-decoration: underline;
  color: var(--primary);
}

.terms-and-conditions ol,
.terms-and-conditions ul {
  padding-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

ul {
  margin: 0px;
}

li {
  margin-bottom: 0px;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.vertical-flex-10 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.vertical-flex-15 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.container {
  display: flex;
  justify-self: center;
  margin-top: 40px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-top: 2em;
  min-height: calc(100vh - 76px);
}

.title {
  font-size: 38px;
  font-weight: bold;
  letter-spacing: -1px;
}

.subtitle {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pulsating-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;
}

.error-text {
  width: 100%;
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  color: red;
  margin-bottom: 5px;
}

.pulsating-circle:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #90ee90;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #90ee90;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

.pulsating-circle2 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;
}

.pulsating-circle2:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #782dc8;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle2:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #782dc8;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.pulsating-circle3 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;
}

.pulsating-circle3:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #dfff00;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle3:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #dfff00;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

.line {
  height: 2px !important;
}
.errorSvg {
  height: 200px;
}

.raised-container {
  width: 100%;
  padding: 30px;
  /* border: 1px solid rgb(184, 184, 184); */
  border-radius: 16px;
  box-shadow: 0px 16px 24px 0px rgba(8, 60, 148, 0.16);
  gap: 20px;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 1200px) {
  .main {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    padding-inline: 1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .rc-footer-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px; /* Adjust this value as needed for spacing between items */
  }
}

@media only screen and (max-width: 610px) {
  .main {
    max-width: 100vw;
    padding-left: 25px;
    padding-right: 25px;
    min-width: 100vw;
    overflow: hidden;
  }

  .container-center {
    justify-content: flex-start;
    padding-top: 100px;
    padding-inline: 20px;
  }
}

.mobile-hidden {
  display: block;
}
.desktop-hidden {
  display: none;
}

@media only screen and (max-width: 410px) {
  .mobile-hidden {
    display: none;
  }
  .desktop-hidden {
    display: block;
  }

  #ck-theme-pre-section {
    display: none !important;
  }
}

.rc-footer {
  position: relative;
  clear: both;
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  line-height: 1.5;
  background-color: #000;
}
.rc-footer a {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  transition: all 0.3s;
}
.rc-footer a:hover {
  color: #40a9ff;
}
.rc-footer-container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 80px 0 20px;
}
.rc-footer-columns {
  display: flex;
  justify-content: space-around;
}
.rc-footer-column {
  margin-bottom: 60px;
}
.rc-footer-column h2 {
  position: relative;
  margin: 0 auto;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}
.rc-footer-column-icon {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 22px;
  text-align: center;
  vertical-align: middle;
  margin-inline-end: 0.5em;
}
.rc-footer-column-icon > span,
.rc-footer-column-icon > svg,
.rc-footer-column-icon img {
  display: block;
  width: 100%;
}
.rc-footer-item {
  margin: 12px 0;
}
.rc-footer-item-icon {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 16px;
  text-align: center;
  vertical-align: middle;
  margin-inline-end: 0.4em;
}
.rc-footer-item-icon > span,
.rc-footer-item-icon > svg,
.rc-footer-item-icon img {
  display: block;
  width: 100%;
}
.rc-footer-item-separator {
  margin: 0 0.3em;
}
.rc-footer-bottom-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px 0;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}
.rc-footer-light {
  color: rgba(0, 0, 0, 0.85);
  background-color: transparent;
}
.rc-footer-light h2,
.rc-footer-light a {
  color: rgba(0, 0, 0, 0.85);
}
.rc-footer-light .rc-footer-bottom-container {
  border-top-color: #e8e8e8;
}
.rc-footer-light .rc-footer-item-separator,
.rc-footer-light .rc-footer-item-description {
  color: rgba(0, 0, 0, 0.45);
}
@media only screen and (max-width: 767.99px) {
  .rc-footer {
    text-align: center;
  }
  .rc-footer-container {
    padding: 40px 0;
  }
  .rc-footer-columns {
    display: block;
  }
  .rc-footer-column {
    display: block;
    margin-bottom: 40px;
  }
  .rc-footer-column:last-child {
    margin-bottom: 0;
  }
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px #6744ce,
    0 0 5px #6744ce;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}

#nprogress .bar {
  height: 2px;
  background-color: var(--primary);
  animation:
    2s linear infinite barprogress,
    0.3s fadein;
  width: 100%;
  box-shadow:
    0 0 15px #782dc8,
    0 0 30px #782dc8,
    0 0 45px #782dc8;
}

#nprogress .spinner-icon {
  border-top-color: transparent;
  border-left-color: transparent;
}
@keyframes barprogress {
  0% {
    background-position: 0% 0;
  }
  to {
    background-position: 125% 0;
  }
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@media screen and (max-width: 768px) {
  #nprogress {
    display: none;
  }
}

@font-face {
font-family: '__gilmer_0557e3';
src: url(/_next/static/media/d048aa968757ade1-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__gilmer_0557e3';
src: url(/_next/static/media/8ca696ff2700c3c2-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: bold;
}

@font-face {
font-family: '__gilmer_0557e3';
src: url(/_next/static/media/3b66d32d841037d7-s.p.otf) format('opentype');
font-display: swap;
font-weight: 800;
font-style: bolder;
}

@font-face {
font-family: '__gilmer_0557e3';
src: url(/_next/static/media/b916f7b1032c8af1-s.p.otf) format('opentype');
font-display: swap;
font-weight: 900;
}@font-face {font-family: '__gilmer_Fallback_0557e3';src: local("Arial");ascent-override: 72.10%;descent-override: 19.16%;line-gap-override: 18.27%;size-adjust: 109.58%
}.__className_0557e3 {font-family: '__gilmer_0557e3', '__gilmer_Fallback_0557e3'
}

